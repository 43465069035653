import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonInput, IonLabel, IonItem, IonButton, IonText } from '@ionic/react';
import { Row, Col, Form, Input, Button } from 'antd';
import { sendPasswordResetEmail } from "../utils/firebase";
import { useState, useEffect } from 'react';
import { EmailInput } from '../components/EmailInput/EmailInput';
import { useDispatch } from 'react-redux';
// import { useNavigate } from "react-router-dom";

function ResetPwdPageComponent() {
    // const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const dispatch = useDispatch();

    // const handleEmailChange = (newEmail: string) => {
    //     setEmail(newEmail);
    // };

    const onFinish = () => {
        sendPasswordResetEmail(email, dispatch);
        // navigate("/login");
    };

    return (
        <IonPage>
            <IonToolbar >
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/"></IonBackButton>
                </IonButtons>
                <IonTitle>Reset password</IonTitle>
            </IonToolbar>

            <IonContent>

                <IonItem>
                    <EmailInput
                        email={email}
                        isValid={isValid}
                        setIsValid={setIsValid}
                        setEmail={setEmail}
                    />
                </IonItem>
                <IonButton expand="full" onClick={onFinish} disabled={!isValid}>Reset Password</IonButton>
            </IonContent>
        </IonPage>
    );
}

export { ResetPwdPageComponent };