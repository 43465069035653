import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonItem, IonLabel, IonDatetimeButton, IonDatetime, IonModal, IonInput, IonButton, IonItemDivider } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { weightData } from '../../../../utils/types';
import { UserContext } from "../../../../App";
import { getWeightData, writeUserWeight } from "../../Services/dashboardServices";
import { dateToString, getDateRelative, getTodaysDay, stringToDate } from "../../../../utils/misc-utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LoadingGenie } from "../../../../components/LoadingGenie/LoadingGenie";
import { WeightEntry, addWeightEntry } from "../../../../redux/dashboardSlice";

type weightInputPageProps = {

}


function WeightInputPage(props: weightInputPageProps) {
    const [selectedDate, setSelectedDate] = useState<string>(getTodaysDay());
    const [weight, setWeight] = useState<number>();
    const weightData: Record<string,number> = useSelector((state: RootState) => state.dashboard.weightEntries);
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    const todaysDate = new Date();
    const dispatch = useDispatch();

    const handleDateChange = (e: CustomEvent) => {
        setSelectedDate(e.detail.value!);
    };

    const handleWeightChange = (e: CustomEvent) => {
        setWeight(e.detail.value);
    };

    function onClickSave() {
        if (user && weight && selectedDate) {
            writeUserWeight(user!, weight!, selectedDate);
            dispatch(addWeightEntry({ date: selectedDate, weight: weight }));
        }
    }



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Weight tracking</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel>Date</IonLabel>

                    <IonDatetimeButton datetime="datetime" ></IonDatetimeButton>

                    <IonModal keepContentsMounted={true}>
                        <IonDatetime
                            id="datetime"
                            presentation="date"
                            value={selectedDate}
                            onIonChange={handleDateChange}
                            max={dateToString(new Date())}></IonDatetime>
                    </IonModal>
                </IonItem>
                <IonItem>
                    <IonLabel>Weight (kg)</IonLabel>
                    <IonInput type="number" value={weight} onIonChange={handleWeightChange} />
                </IonItem>

                <IonButton onClick={() => onClickSave()}>
                    Save
                </IonButton>
                <IonItemDivider>
                    <IonLabel>Logs</IonLabel>
                </IonItemDivider>

                {Object.entries(weightData).map(([date, weight], index) => (
                    weight ? (
                        <IonItem key={index}>
                            <IonLabel>
                                Date: {date}, Weight: {weight}
                            </IonLabel>
                        </IonItem>
                    ) : null
                ))}
            </IonContent>

        </IonPage >
    )
}

export { WeightInputPage };