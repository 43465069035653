import { Col, Row, Alert,message } from 'antd';
import type { } from 'antd';

import { useState } from 'react';

import NutritionDetailsCard from './NutritionDetailsCard/NutritionDetailsCard';
import LoadingCardComponent from '../../../components/LoadingCard/LoadingCardComponent';
import { mealList, sumMacros } from '../../../utils/types';

import { SignUpModalComponent } from '../../../components/SignUpModal/SignUpModal';
import { GetPremiumModalComponent } from '../../../components/GetPremiumModal/GetPremiumModal';

import RecipeCardFunctionComponent from './RecipeCard/RecipeCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type mealPlanComponentProps = {
    meals: mealList | undefined,
    sumMacros: sumMacros,
    setSumMacros: React.Dispatch<React.SetStateAction<sumMacros>>,
    //getMeals: () => void,
    // setMeals: React.Dispatch<React.SetStateAction<mealList | undefined>>,
    isDoe: boolean,
    date: string,
}


function MealPlanComponent(props: mealPlanComponentProps) {

    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);
    const isPremium = useSelector((state: RootState) => state.auth.isPremium);
    const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
    const dispatch = useDispatch();


    // -----------------------------------------------------------------------------------------------------------------------------------
    // Error messages   
    // -----------------------------------------------------------------------------------------------------------------------------------
    const [messageApi, contextHolder] = message.useMessage();
    const [errorList, setErrorList] = useState<Array<String>>([]); // used to display errors catched to user

    const error = () => {
        messageApi.open({
            type: 'warning',
            content: 'This feature is restricted to premium users',
        });
    };

    const onCloseError = (e: String) => {
        setErrorList([...errorList].splice(errorList.indexOf(e), 1));
    };

    // -----------------------------------------------------------------------------------------------------------------------------------
    // journal page state 
    // -----------------------------------------------------------------------------------------------------------------------------------
    const [isLoading, setIsLoading] = useState<boolean>(false); // loading state of journal page

    // -----------------------------------------------------------------------------------------------------------------------------------
    // Get Premium Modal controls
    // -----------------------------------------------------------------------------------------------------------------------------------
    // const [showGetPremiumModal, setShowGetPremiumModal] = useState<boolean>(false);
    // const handleOkGetPremiumModal = () => {
    //     if (user && !isPremium) {
    //         // createCheckoutSession(user.uid)
    //     }
    //     else {
    //         setRedirectionLoading(true);
    //         // navigate('/signup');
    //     }
    // }

    // const handleCancelGetPremiumModal = () => {
    //     setShowGetPremiumModal(false);
    // }

    // -----------------------------------------------------------------------------------------------------------------------------------
    // Signup Modal controls
    // -----------------------------------------------------------------------------------------------------------------------------------
    // const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);


    // const handleCancelSignUpModal = () => {
    //     setShowSignUpModal(false);
    // }

    // -----------------------------------------------------------------------------------------------------------------------------------
    // Main return for dashboard component 
    // -----------------------------------------------------------------------------------------------------------------------------------

    return (
        <>
            {contextHolder}

            {errorList.length > 0 && errorList.map((error, index) => {
                return (
                    <Row key={index} >
                        <Alert message={error} type="error" style={{ width: '100%' }} closable onClose={() => onCloseError(error)} />
                    </Row>
                )
            })}

            <Row justify="center" style={{ marginTop: '20px', marginBottom: '20px' }} key="row1">
                {
                    <Col lg={14} md={18} sm={23} xs={23} key="colNutrition">
                        {isLoading ?
                            <LoadingCardComponent /> :
                            <NutritionDetailsCard
                                key={'nutritionCard'}
                                sumMacros={props.sumMacros}
                            // disableMacros={disableMacros}
                            // proteinObjective={proteinObjective}
                            // setProteinObjective={setProteinObjective}
                            // carbsObjective={carbsObjective}
                            // setCarbsObjective={setCarbsObjective}
                            // fatObjective={fatObjective}
                            // setFatObjective={setFatObjective}
                            // calorieObjective={calorieObjective}
                            // onChangeSwitch={onChangeEditMacrosSwitch}
                            // // onClickAdjustRecipe={onClickAdjustRecipe}
                            // onChangeCarbObjective={onChangeCarbsObjective}
                            // onChangeProteinObjective={onChangeProteinObjective}
                            // onChangeFatObjective={onChangeFatObjective}
                            // restricted={isPremium}
                            />
                        }
                    </Col>
                }
            </Row>
            {
                !isLoading && props.meals !== undefined && Object.entries(props.meals).length > 0
                && Object.entries(props.meals).map(meal => {
                    return (
                        <Row justify="center" style={{ marginBottom: '20px' }}>
                            <Col lg={14} md={18} sm={23} xs={23} key={"colMeal" + meal[0]} >
                                <RecipeCardFunctionComponent
                                    key={"rcpCard" + meal[0]}
                                    mealID={meal[0]}
                                    meal={meal[1]}
                                    date={props.date!}
                                    //setMeals={props.setMeals}
                                    // onChangeQuantitiesFunction={onChangeQuantities}
                                    // disableQuantities={!disableMacros}
                                    // onClickDeleteRecipe={onClickDeleteRecipe}
                                // lastChanged={lastChanged}
                                // setLastChanged={setLastChanged}                              
                                // restricted={false}
                                />

                            </Col>
                        </Row>
                    );

                })
            }

            <Row style={{ marginBottom: '20px' }} justify={'center'}>
                {/* <Col>
                        <Button type='primary' style={{ margin: 0, }} onClick={onClickImportRecipe}>Import Recipe</Button>
                    </Col> */}
            </Row>
            {/* {!props.isDoe &&
                <Row style={{ marginBottom: '20px' }} justify={'center'}>

                    <Col>
                        <IonButton fill="clear" onClick={() => onClickAddRecipe()}>Add recipe</IonButton>
                    </Col>
                </Row>} */}



            {/* {isAdmin &&
                <Row justify="center" style={{ marginBottom: '20px' }}>
                    <Col lg={14} md={18} sm={23} xs={23} >
                        <SaveDoeCardComponent meals={props.meals} />
                    </Col>
                </Row>
            } */}

            {/* <ImportRecipeModal
                    open={openImportRecipeModal}
                    handleOk={handleOKImportRecipeModal}
                    confirmLoading={confirmLoadingImportRecipeModal}
                    handleCancel={handleCancelImportRecipeModal}
                    importFrom={importFromRecipeModal}
                    setImportFrom={setImportFromRecipeModal}
                    setMeals={setMeals}
                /> */}



            {/* <GetPremiumModalComponent handleCancel={handleCancelGetPremiumModal} isModalOpen={showGetPremiumModal} /> */}
            {/* <SignUpModalComponent isModalOpen={showSignUpModal} handleCancel={handleCancelSignUpModal} handleOk={handleOkGetPremiumModal} /> */}

        </>
    );
}


export { MealPlanComponent };
