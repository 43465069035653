// store.tsx
import { UnknownAction, configureStore } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { mealReducer } from './mealsSlice';
import { saveMealMiddleware } from './mealsMiddleware';
import authReducer, { AuthState } from './authSlice'; // Replace with the actual path to your authSlice file
import { mealList } from '../utils/types';
import { RecipeState } from './recipeSlice';
import recipeReducer from './recipeSlice'; // Import recipeReducer as the default import
import dashboardReducer, { DashboardState } from './dashboardSlice';
import { UXState } from './uxSlice';
import uxReducer from './uxSlice';
import { ErrorState } from './errorSlice';
import errorReducer from './errorSlice';
// export interface RootState {
//   meals: {
//     meals: mealList;
//     date: string;
//   },
//   auth: AuthState;
//   // Include other parts of your Redux state here
// }


export interface RootState {
  meals: { [date: string]: mealList };
  auth: AuthState;
  recipe: RecipeState;
  dashboard: DashboardState;
  ux: UXState,
  error: ErrorState[];
}

const store = configureStore({
  reducer: {
    meals: mealReducer,
    auth: authReducer,
    recipe: recipeReducer,
    dashboard: dashboardReducer,
    ux: uxReducer,
    error: errorReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveMealMiddleware),
  devTools: process.env.NODE_ENV!== 'production',
});

export type AppDispatch = typeof store.dispatch;

export { store };

