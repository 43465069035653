import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorState {
    id: string;
    errorMessage: string;
}

const initialState: ErrorState[] = [];

const errorSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        addError: (state, action: PayloadAction<ErrorState>) => {
            state.push(action.payload);
        },
        removeError: (state, action: PayloadAction<string>) => {
            return state.filter(error => error.id !== action.payload);
        },
    },
});

export const { addError, removeError } = errorSlice.actions;

export default errorSlice.reducer;

export type { ErrorState };