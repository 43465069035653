import { IonFab, IonFabButton, IonIcon, IonFabList } from "@ionic/react"
import { link, documentOutline, documentTextOutline, addOutline } from "ionicons/icons"
import './FabButton.css';
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { mealList } from "../../../../utils/types";
import { useHistory } from "react-router";


type FabButtonProps = {
    onClickAddEmptyRecipe: () => void,
    date: string,
}


function FabButton(props: FabButtonProps) {

    const history = useHistory();

    const user = useSelector((state: RootState) => state.auth.userUid);
    const meals: mealList | undefined = useSelector((state: RootState) => state.meals[props.date]);

    function onClickAddRecipe(){
        history.push({
            pathname:'/mealplan/add-recipe',
            state:{date:props.date}
        });
    }


    return (
        <>
            <IonFab slot="fixed" vertical="bottom" horizontal="end" className="my-fab">

                <IonFabButton className="main-button">
                    <IonIcon icon={addOutline}></IonIcon>
                    {/* <img src="/noun-genie-white-1024.png" alt="button icon" style={{ width: '70%', height: '70%' }} /> */}
                </IonFabButton>

                <IonFabList side="top">
                    <div className="fab-item">
                        <IonFabButton onClick={props.onClickAddEmptyRecipe}>
                            <IonIcon icon={documentOutline} />
                        </IonFabButton>
                        <div className="label">Add empty recipe</div>
                    </div>

                    <div className="fab-item">
                        <IonFabButton onClick={onClickAddRecipe}>
                            <IonIcon icon={documentTextOutline} />
                        </IonFabButton>
                        <div className="label">Add recipe</div>
                    </div>

                    <div className="fab-item">
                        <IonFabButton onClick={() => {history.push({pathname:'/mealplan/import-recipe', state:{date:props.date}})}}>
                            <IonIcon icon={link} />
                        </IonFabButton>
                        <div className="label">Import</div>
                    </div>

                </IonFabList>
            </IonFab>
        </>
    )
}

export { FabButton }