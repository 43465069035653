import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonItem, IonSelect, IonReorderGroup, IonList, IonReorder, IonLabel, ItemReorderEventDetail, IonSelectOption } from "@ionic/react"
import {  useEffect, useState } from "react";
import { mealType, OnboardingUserFormData } from "../../../../utils/types";
import { DoeStructureComponent } from "./DoeStructureComponent";

 
type DoeStructurePageProps = {
    goToNextSlide: () => void;
    goToPrevSlide: () => void;
    setUserData: React.Dispatch<React.SetStateAction<OnboardingUserFormData>>;

}



function DoeStructurePage(props: DoeStructurePageProps) {
    const initialMeals = [{ key: 0, type: 'Breakfast' }, { key: 1, type: 'Meal' }, { key: 2, type: 'Snack' }, { key: 3, type: 'Meal' }];
    const [mealList, setMealList] = useState<mealType[]>(initialMeals);

    function onClickNext(){
        props.setUserData(prev => {
            return {
                ...prev,
                meals: mealList
            }
        });
        props.goToNextSlide();
    }


    

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Day of eating structure
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <DoeStructureComponent
                mealList={mealList}
                setMealList={setMealList}
                
                />
                <div className="button-container">

                <IonButton shape="round" onClick={props.goToPrevSlide}>Prev</IonButton>
                <IonButton shape="round" onClick={() => onClickNext()}>Next</IonButton>
            </div>
            </IonContent>
        </IonPage>
    )
}

export { DoeStructurePage }