import { IonList, IonSpinner, IonItem, IonLabel, IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../redux/store";
import { Recipe, emptyRecipe } from "../../../utils/types";
import { getUsersMyRecipes } from "../services/RecipePageServices";
import { RecipeCardPresentation } from "./RecipeCardPresentation";
import { add } from "ionicons/icons";
import '../../MealPlan/Components/FabButton/FabButton.css';
import { setViewingRecipeId, setLoadedUsersRecipes } from "../../../redux/recipeSlice";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";


type BrowseMyRecipesViewProps = {

}

function BrowseMyRecipesView(props: BrowseMyRecipesViewProps) {

    const user = useSelector((state: RootState) => state.auth.userUid);
    // const [recipeStack, setRecipeStack] = useState<Recipe[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const recipes = useSelector((state: RootState) => {
        const allRecipes = state.recipe.recipes;
        if (!allRecipes) return null;
        const filteredEntries = Object.entries(allRecipes).filter(([_, { usersRecipe }]) => usersRecipe);
        return Object.fromEntries(filteredEntries);
    });
    // filter recipes on the basis of usersRecipe

    const loadedUsersRecipes = useSelector((state: RootState) => state.recipe.loadedUsersRecipes);

    async function initializeStack() {
        if (!loadedUsersRecipes) {
            setLoading(true);
            var temp: Recipe[] = [];
            // if (props.segment == "browse") {
            temp = await getUsersMyRecipes(user!);
            dispatch({ type: 'recipe/addRecipesToStore', payload: [temp, true] });
            // }
            // else if (props.segment == "liked") {
            //     temp = await getUsersRecipes(user!);
            // }
            // setRecipeStack(temp);
            dispatch(setLoadedUsersRecipes(true));
            setLoading(false);
        }
    }

    useEffect(() => {
        initializeStack();
    }, [])

    function onClickAddMyRecipe() {
        var tempRecipe = emptyRecipe;
        tempRecipe = {...tempRecipe}
        dispatch(setViewingRecipeId(tempRecipe.recipeId));
        history.push({pathname: '/recipes/singleRecipeView/', state: {recipe: tempRecipe, usersRecipe: true, editMode:true}})
    }

    return (
        <>
            {loading ?
                <LoadingGenie/>
                :
                recipes && Object.entries(recipes).map(([recipeId, { recipe, usersRecipe }]) => (
                    (recipe.recipeId !== '-1') &&

                    <RecipeCardPresentation key={recipeId} recipe={recipe} usersRecipe={usersRecipe} disableLike={true} />
                ))}

            <IonFab slot="fixed" vertical="bottom" horizontal="end" className="my-fab">
            <IonFabButton className="main-button" onClick={onClickAddMyRecipe}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
        </>
    )
}

export { BrowseMyRecipesView };