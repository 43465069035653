import { IonPage, IonContent, IonList, IonItem, IonLabel, IonButton, IonHeader, IonTitle, IonToolbar, IonRouterOutlet } from "@ionic/react";
import { Route, RouteComponentProps } from "react-router-dom";
import { MacrosPageComponent } from "../MacrosPage/macrosPage";
import { ProfilePageComponent } from "../ProfilePage/profilePage";
import { SupportPageComponent } from "../SupportPage/support";
import { useDispatch } from "react-redux";
import { signOut } from "../../../../redux/authSlice";
import { AppDispatch } from "../../../../redux/store";

function SettingsMenuComponent({ match }: RouteComponentProps) {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Settings</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem routerLink='/settings/profile'>
                        <IonLabel>Profile</IonLabel>
                    </IonItem>

                    <IonItem routerLink='/settings/macros'>
                        <IonLabel >Macros</IonLabel>
                    </IonItem>

                    <IonItem routerLink='/settings/doe'>
                        <IonLabel >Day of eating structure</IonLabel>
                    </IonItem>

                    <IonItem routerLink='/settings/support'>
                        <IonLabel >Support</IonLabel>
                    </IonItem>
                   

                </IonList>
                <IonButton expand="block" onClick={() =>dispatch(signOut())}>LogOut</IonButton>
                <div style={{textAlign:'center',color:'grey',marginTop:'20px'}}>v 1.240816</div>

            </IonContent>
        </IonPage>
    )
}

export { SettingsMenuComponent };