import { IonPage, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonItem, IonInput, IonButton } from "@ionic/react"
import { auth } from "../../../../utils/firebase"
import { DoeStructureComponent } from "../../../Onboarding/Components/Slides/DoeStructureComponent"
import { SetStateAction, useEffect, useState } from "react"
import { mealType } from "../../../../utils/types"
import { useAuthState } from "react-firebase-hooks/auth"
import { useDispatch, useSelector } from "react-redux"
import { setDoeStructure } from "../../../../redux/authSlice"
import { saveUserGoal } from "../../../../utils/db_connectors/database-connectors"

type MealStructurePageProps = {

}


function MealStructurePage(props: MealStructurePageProps) {

    const [mealList, setMealList] = useState<mealType[]>([]);
    const userId = useSelector((state: any) => state.auth.userUid);
    const userDoeStructure = useSelector((state: any) => state.auth.userGoal?.meals);
    const userGoal = useSelector((state: any) => state.auth.userGoal);
    const dispatch = useDispatch();

    useEffect(()=>{
        console.log('usergoal', userGoal);
        console.log('userDoeStructure', userDoeStructure);
        setMealList(userDoeStructure ? userDoeStructure : []);
    },[userDoeStructure])


    function onClickSave(){
        dispatch(setDoeStructure(mealList));
        // save to DB 
        saveUserGoal(userId,  {...userGoal, meals: mealList});
    }

    return(
        <IonPage>

            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/settings"></IonBackButton>
                </IonButtons>
                <IonTitle>Day of eating</IonTitle>
            </IonToolbar>

            <IonContent>
                <DoeStructureComponent 
                mealList={mealList} 
                setMealList={setMealList}                    
                />
                <IonButton expand="block" onClick={onClickSave}>Save</IonButton>
            </IonContent >

        </IonPage >
    )
}

export {MealStructurePage}