import { IonPage, IonContent, IonToolbar, IonButtons, IonTitle, IonBackButton, IonItem, IonInput, IonButton, IonAlert, IonHeader } from "@ionic/react";
import { useState } from "react";
import { OnboardingUserFormData } from "../../../../utils/types";
import { Preview } from "@mui/icons-material";

type slide1NameProps = {
    goToNextSlide: () => void;
    goToPrevSlide: () => void;
    username: string | undefined;
    setOnboardingFormData: React.Dispatch<React.SetStateAction<OnboardingUserFormData>>;
}

function Slide1Name(props: slide1NameProps) {
    const [isIncompleteFormAlertOpen, setIsUncompleteFormAlertOpen] = useState(false);

    const setUsername = (e: string) => {
        props.setOnboardingFormData((prev: OnboardingUserFormData) => {
            // User is updating form data, update username
            return { ...prev, username: e };
        });
    }

    const handleClickNext = () => {
        if (props.username === '') {
            setIsUncompleteFormAlertOpen(true);
        }
        else {
            props.goToNextSlide();
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {/* <IonButtons slot="start">
                    <IonBackButton defaultHref="/"></IonBackButton>
                </IonButtons> */}
                    <IonTitle>User name</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonItem>
                    <IonInput
                        label="First name"
                        placeholder=""
                        value={props.username}
                        onIonChange={e => setUsername(e.detail.value!)}
                        class='ion-text-end'
                    >

                    </IonInput>
                </IonItem>
                <div className="button-container">
                    <IonButton shape="round" onClick={props.goToPrevSlide}>Prev</IonButton>

                    <IonButton shape="round" onClick={handleClickNext}>Next</IonButton>
                </div>
                <IonAlert
                    isOpen={isIncompleteFormAlertOpen}
                    header="Missing values"
                    // subHeader="A Sub Header Is Optional"
                    message="Please fill in your name."
                    buttons={['Ok']}
                    onDidDismiss={() => setIsUncompleteFormAlertOpen(false)}
                ></IonAlert>
            </IonContent>
        </IonPage>
    )
}

export { Slide1Name };