import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ErrorMessage, Ingredient, Recipe } from "../utils/types";
import { deleteUsersRecipe } from "../utils/db_connectors/database-connectors";
import { saveRecipeToCommon, saveUsersRecipe } from "../features/MealPlan/Services/mealplanServices";
import { setLogLevel } from "firebase/app";
import { addError } from "./errorSlice";

interface RecipeState {
  recipes: { [recipeId: string]: { recipe: Recipe, usersRecipe: boolean } } | null;
  // dbRecipes: { [recipeId: string]: Recipe | null } | null;
  // usersRecipes: { [recipeId: string]: Recipe | null } | null;
  viewingRecipeId: string | null;
  isImport: boolean;
  isUsersRecipe: boolean;
  editMode: boolean;
  loadedUsersRecipes: boolean;
  loadedDbRecipes: boolean;
}
const initialState: RecipeState = {
  recipes: null,
  viewingRecipeId: null,
  editMode: false,
  isImport: false,
  isUsersRecipe: false,
  loadedDbRecipes: false,
  loadedUsersRecipes: false,
};

export const deleteUsersRecipeThunk = createAsyncThunk(
  'recipe/deleteRecipe',
  async ({ user_id, recipeId }: { user_id: string; recipeId: string }, thunkAPI) => {
    try {
      await deleteUsersRecipe(user_id, recipeId);
      // remove from state also

    } catch (error) {
      return thunkAPI.rejectWithValue({ error: (error as Error).message });
    }

  }
);

export const saveRecipe = createAsyncThunk(
  'recipe/saveRecipe',
  async ({ user_id, recipe }: { user_id: string; recipe: Recipe }, thunkAPI) => {
    try {
      var res = await saveUsersRecipe(user_id, recipe, thunkAPI.dispatch);
      if('errors' in res && (res.errors as ErrorMessage[]).length > 0){
        for (const error of res.errors as ErrorMessage[]) {
            thunkAPI.dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
        }
      }
      return recipe;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: (error as Error).message });
    }
  }
);

export const saveRecipeToCommonDB = createAsyncThunk(
  'recipe/saveRecipeToCommonDB',
  async ({ recipe }: { recipe: Recipe }, thunkAPI) => {
    try {
      await saveRecipeToCommon(recipe);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: (error as Error).message });
    }
  }
);

const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    addRecipesToStore: (state, action: PayloadAction<[Recipe[], boolean]>) => {
      const [recipes, usersRecipe] = action.payload;
      if (state.recipes === null) {
        state.recipes = {};
      }
      recipes.forEach((recipe) => {
        if (state.recipes && state.recipes[recipe.recipeId]) {
          state.recipes[recipe.recipeId].recipe = recipe;
        } else {
          state.recipes![recipe.recipeId] = { 'recipe': recipe, 'usersRecipe': usersRecipe };
        }
      })
    },
    // removeRecipeFromStore: (state, action: PayloadAction<string>) => {
    //   if (state.recipes && action.payload in state.recipes) {
    //     delete state.recipes[action.payload];
    //   }
    // },
    setLoadedUsersRecipes: (state, action: PayloadAction<boolean>) => {
      state.loadedUsersRecipes = action.payload;
    },
    setLoadedDbRecipes: (state, action: PayloadAction<boolean>) => {
      state.loadedDbRecipes = action.payload;
    },
    clearRecipe: (state) => {
      state.viewingRecipeId = null;
    },
    editRecipeName: (state, action: PayloadAction<string | null | undefined>) => {

      if (state.recipes && state.viewingRecipeId && action.payload) {
        state.recipes[state.viewingRecipeId].recipe.recipeName = action.payload;
      }
    },
    editUsersRecipe: (state, action: PayloadAction<boolean>) => {
      state.isUsersRecipe = action.payload;
    },
    editEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setIsImport: (state, action: PayloadAction<boolean>) => {
      state.isImport = action.payload;
    },
    setViewingRecipeId: (state, action: PayloadAction<string>) => {
      console.log('editRecipe Id in slice', action.payload);
      state.viewingRecipeId = action.payload
    },
    editIngredientInRecipe: (state, action: PayloadAction<{ key: number, ingredient: Ingredient }>) => {
      const { key, ingredient } = action.payload;
      if (state.recipes && state.recipes['-1']) {
        const recipe = state.recipes['-1'].recipe;
        const ingredientIndex = recipe.ingredients.findIndex(ing => ing.key === key);
        if (ingredientIndex !== undefined && ingredientIndex !== -1) {
          state.recipes['-1'].recipe.ingredients[ingredientIndex] = ingredient;
        }
      }
    },
    replaceIngredientInRecipe: (state, action: PayloadAction<{ key: number, ingredient: Ingredient }>) => {
      const { key, ingredient } = action.payload;
      if (state.recipes && state.recipes['-1']) {
        var recipe = state.recipes['-1'].recipe;
        const ingredientIndex = state.recipes['-1'].recipe?.ingredients.findIndex(ing => ing.key === key);
      //keep original text and tagged named and tagge unit of original ingredient
        const originalText = recipe?.ingredients[ingredientIndex!]['original text'];
        const taggedName = recipe?.ingredients[ingredientIndex!]['tagged named'];
        const taggedUnit = recipe?.ingredients[ingredientIndex!]['tagged unit'];
        if (ingredientIndex !== undefined && ingredientIndex !== -1) {
          recipe!.ingredients[ingredientIndex] = { ...ingredient, key: key, 'original text': originalText, 'tagged named': taggedName, 'tagged unit': taggedUnit };
        }
      }
    },
    lockIngredientInRecipe: (state, action: PayloadAction<number>) => {
      if (state.recipes && state.recipes['-1']) {
        const recipe = state.recipes['-1'].recipe;
        const ingredient = recipe.ingredients.find(ingredient => ingredient.key === action.payload);
        if (ingredient) {
          ingredient.locked = !ingredient.locked;
        }
      }
    },
    deleteIngredientFromRecipe: (state, action: PayloadAction<number>) => {
      if (state.recipes && state.recipes['-1']) {
        const recipe = state.recipes['-1'].recipe;
        recipe.ingredients = recipe.ingredients.filter(ingredient => ingredient.key !== action.payload);
      }
    },
    addIngredientToRecipe: (state, action: PayloadAction<Ingredient>) => {
      // debugger;
      if (state.recipes && state.recipes['-1']) {
        const recipe = state.recipes['-1'].recipe;
        recipe.ingredients.push(action.payload);
        console.log('add ig recipe id', state.viewingRecipeId);
        console.log('recipe.ingredients', Array.from(recipe.ingredients)); // Convert proxy to plain array
      }
    },
    updateServings: (state, action: PayloadAction<number>) => {
      if (state.recipes && state.recipes['-1']) {
        const recipe = state.recipes['-1'].recipe;
        recipe.servings = action.payload;
      }
    },
    editMealType: (state, action: PayloadAction<{ newMealType: string[] }>) => {
      if (state.recipes && state.recipes['-1']) {
        const recipe = state.recipes['-1'].recipe;
        recipe.mealType = action.payload.newMealType; // Replace the mealType with the new array
      }
    },
    editLikeStatus: (state, action: PayloadAction<{ recipeId: string, likedStatus: boolean | null}>) => {
      if (state.recipes) {
        const recipe = state.recipes[action.payload.recipeId].recipe;
        recipe.like = action.payload.likedStatus;
      }
  }},
  extraReducers: (builder) => {
    builder.addCase(deleteUsersRecipeThunk.fulfilled, (state, action) => {
      // handle state update when deleteRecipe is successful
      const { recipeId } = action.meta.arg;
      if (state.recipes && recipeId in state.recipes) {
        delete state.recipes[recipeId];
      }
    });
    builder.addCase(saveRecipe.fulfilled, (state, action) => {
      // handle state update when saveRecipe is successful
      const savedRecipe = action.payload as unknown as Recipe;
      if (!state.recipes) {
        state.recipes = {};
      }
      // Update the usersRecipes state with the new or updated recipe
      // This assumes savedRecipe has an id property
      if (savedRecipe && savedRecipe.recipeId) {
        // Update the usersRecipes state with the new or updated recipe
        if(state.recipes[savedRecipe.recipeId]){
          state.recipes[savedRecipe.recipeId].recipe = savedRecipe;
        }
        else{ //i.e. it's a new recipe, like an import
          state.recipes[savedRecipe.recipeId] = { 'recipe': savedRecipe, 'usersRecipe': true };
        }
      } else {
        // Handle the case where savedRecipe or savedRecipe.id is undefined
        // This could be logging an error, setting a default value, etc.
        console.error('Saved recipe is undefined or missing an id');
      }
    });
    builder.addCase(saveRecipeToCommonDB.fulfilled, (state, action) => {
      // handle state update when saveRecipeToCommonDB is successful
      const savedRecipe = action.payload as unknown as Recipe;
      if (!state.recipes) {
        state.recipes = {};
      }
      // Update the dbRecipes state with the new or updated recipe
      // This assumes savedRecipe has an id property
      if (savedRecipe && savedRecipe.recipeId) {
        // Update the dbRecipes state with the new or updated recipe
        state.recipes[savedRecipe.recipeId].recipe = savedRecipe;
      } else {
        // Handle the case where savedRecipe or savedRecipe.id is undefined
        // This could be logging an error, setting a default value, etc.
        console.error('Saved recipe is undefined or missing an id');
      }
    });
  },

});

export const {
  editMealType,
  editRecipeName,
  replaceIngredientInRecipe,
  updateServings,
  clearRecipe,
  editEditMode,
  editUsersRecipe,
  setViewingRecipeId,
  editIngredientInRecipe,
  addIngredientToRecipe,
  deleteIngredientFromRecipe,
  lockIngredientInRecipe,
  addRecipesToStore,
  setLoadedDbRecipes,
  setLoadedUsersRecipes,
  editLikeStatus,
  setIsImport
} = recipeSlice.actions;

export default recipeSlice.reducer;

export type { RecipeState };

