import { IonButton, IonContent, IonGrid, IonHeader, IonItem, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { createGesture, Gesture } from '@ionic/core';
import React, { useState, useEffect, useRef, useContext } from 'react';
import './RecipeSwipePage.css'; // Import your CSS file
import { RecipeCard } from "./RecipeCard";
import { card } from "ionicons/icons";
import { UserContext } from "../../../../App";
import { Recipe } from "../../../../utils/types";
import { loadOnboardingRecipes } from "../../Services/onboardingServices";
import { RecipeCardWrapper } from "./RecipeCardWrapper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type RecipeSwipePageProps = {
    goToNextSlide: () => void;
    goToPrevSlide: () => void;
    setOnboardingRecipeLikes: React.Dispatch<React.SetStateAction<{ recipe_id: string; likeStatus: boolean; }[]>>
}

const RecipeSwipePage: React.FC<RecipeSwipePageProps> = (props: RecipeSwipePageProps) => {
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    const [recipeStack, setRecipeStack] = useState<Recipe[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [isSwipeComplete, setIsSwipeComplete] = useState(false);

    const getRecipes = async () => {
        setIsLoading(prev => true);
        try {
            const res = await loadOnboardingRecipes();
            setRecipeStack(prev => res);
        } catch (error) {
            console.error('Error loading recipes:', error);
        } finally {
            setIsLoading(prev => false);
        }
    };

    useEffect(() => {
        getRecipes();
    }, []);

    function onSwipeRight(recipeId: string) {
        props.setOnboardingRecipeLikes(prev => [...prev, { recipe_id: recipeId, likeStatus:true }]);
        // likeRecipe(user?.uid, recipeId, true);

    }

    function onSwipeLeft(recipeId: string) {
        props.setOnboardingRecipeLikes(prev => [...prev, { recipe_id: recipeId, likeStatus:false }]);
        // likeRecipe(user?.uid, recipeId, false);

    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                <IonTitle>Recipe Swipe Page</IonTitle>
                </IonToolbar>
            </IonHeader>
        <IonContent>
        <IonGrid className='container'>

            <IonRow>{isLoading ? <p>Loading</p> :
                <RecipeCardWrapper recipeStack={recipeStack} onSwipeRight={onSwipeRight} onSwipeLeft={onSwipeLeft} />}

            </IonRow>
            <div className="button-container">
                <IonButton shape="round" onClick={props.goToPrevSlide}>Prev</IonButton>
                <IonButton shape="round" onClick={props.goToNextSlide}>Next</IonButton>
            </div>
        </IonGrid>
        </IonContent>
        </IonPage>
    )
}

export { RecipeSwipePage };