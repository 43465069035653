import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon } from "@ionic/react";
import { Chart } from "chart.js";
import { useRef, useEffect, useState, useContext } from "react";
import { getLast7Days, getLast7DaysFullString, getWeightData } from "../../Services/dashboardServices";
import { weightData } from "../../../../utils/types";
import { UserContext } from "../../../../App";
import { getTodaysDay, dateToString, getDateRelative } from "../../../../utils/misc-utils";
import { get } from "lodash";
import { addOutline } from "ionicons/icons";

type weightTrackingCardComponentProps = {
    weightData: Record<string, number>;
}


function WeightTrackingCardComponent(props: weightTrackingCardComponentProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const last7daysFullStrings = getLast7DaysFullString();
    const last7daysDisplay = getLast7Days();

    const dailyWeightDataArray = last7daysFullStrings.map(date => {
        const weightForDate = props.weightData[date];
        return weightForDate || null;
    });

    useEffect(() => {
        if (canvasRef.current) {
            const chart = new Chart(canvasRef.current, {
                type: 'line',
                data: {
                    labels: last7daysDisplay,
                    datasets: [{
                        label: 'Weight (kg)',
                        data: dailyWeightDataArray,
                        borderColor: 'rgba(75,192,192,1)',
                        fill: false
                    }]
                },
                options: {
                    responsive: true,

                }
            });

            return () => chart.destroy();
        }
    }, [props.weightData]);

    return (
        <IonCard >
            <IonCardHeader>
                <IonCardTitle>Weight tracking</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <canvas ref={canvasRef} style={{ width: "100%" }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IonButton fill="clear" shape="round" routerLink="/dashboard/weightInput">
                        <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                    </IonButton>
                </div>
            </IonCardContent>
        </IonCard>
    )
}

export { WeightTrackingCardComponent };