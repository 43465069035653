import logo from '../../assets/noun-genie-1637607-outlined.svg';
import './LoadingGenie.css'
import { IonImg } from '@ionic/react';


function LoadingGenie() {

    return (
        <div style={{ textAlign: 'center' }} className="container">
            <IonImg src={logo} alt="Logo" className="floating" style={{marginTop:'40%'}} />
            {/* <p>Loading</p> */}
        </div>
    )
}

export { LoadingGenie };