import { useContext, useEffect, useState } from "react";
import { Recipe } from "../../../utils/types"
import { RecipeCardPresentation } from "./RecipeCardPresentation"
import { UserContext } from "../../../App";
import { getRecipesToBrowse, getUsersRecipes } from "../services/RecipePageServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { IonSearchbar, IonSelect, IonSelectOption, IonSpinner } from "@ionic/react";
import { filter } from "lodash";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { setLoadedDbRecipes } from "../../../redux/recipeSlice";


type browseRecipeCardViewProps = {
}

function BrowseRecipeCardsView(props: browseRecipeCardViewProps) {
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    const [searchText, setSearchText] = useState<string>("");
    const [filterValue, setFilterValue] = useState<string>("all");

    const [loading, setLoading] = useState(false);
    const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);

    //get recipes from the store, filter the ones that are users' as we're in the db view
    const recipes = useSelector((state: RootState) => {
        const allRecipes = state.recipe.recipes;
        if (!allRecipes) return null;
        const filteredEntries = Object.entries(allRecipes)
            .filter(([_, { usersRecipe }]) => !usersRecipe)
            .map(([_, { recipe }]) => recipe);        
        return filteredEntries;
    });


    const loadedDbRecipes = useSelector((state: RootState) => state.recipe.loadedDbRecipes);

    const dispatch = useDispatch();
    
    async function initializeStack() {
        if (!loadedDbRecipes) {
            setLoading(true);
            var temp: Recipe[] = [];
            // if (props.segment == "browse") {
            temp = await getRecipesToBrowse(0, user!, '')
            dispatch({ type: 'recipe/addRecipesToStore', payload: [temp, false] });
            // }
            // else if (props.segment == "liked") {
            //     temp = await getUsersRecipes(user!);
            // }
            // setRecipeStack(temp);
            dispatch(setLoadedDbRecipes(true));
            setLoading(false);
        }
    }

    useEffect(() => {
        initializeStack();
    }, [])

    useEffect(() => {
    
        let filteredRecipes: Recipe[] = [];
        if (recipes) {
            switch(filterValue) {
                case 'liked':
                    filteredRecipes = Object.values(recipes).filter((recipe) => recipe.like);
                    break;
                case 'disliked':
                    filteredRecipes = Object.values(recipes).filter((recipe) => recipe.like === false);
                    break;
                case 'neutral':
                    filteredRecipes = Object.values(recipes).filter((recipe) => recipe.like === null || recipe.like === undefined);
                    break;
                case 'all':
                    filteredRecipes = Object.values(recipes).map((recipe) => recipe);
                    break;
            }

            if (searchText) {
                filteredRecipes = filteredRecipes.filter(recipe =>
                    recipe.recipeName.toLowerCase().includes(searchText.toLowerCase())
                );
            }

        }
    
        setFilteredRecipes(filteredRecipes);
    
    }, [filterValue, searchText, loadedDbRecipes]);


    return (
        <>
            <IonSearchbar
                style={{ width: '100%' }}
                value={searchText}
                onKeyUp={e => setSearchText((e.target as HTMLInputElement).value)}
                onIonClear={() => setSearchText('')}
            // onKeyDown={handleKeyDown}
            >
            </IonSearchbar>
            <IonSelect placeholder="Filter" value={filterValue} onIonChange={(e) => setFilterValue(prev => (e.target as HTMLIonSelectElement).value)}>
                <IonSelectOption value="liked">Liked</IonSelectOption>
                <IonSelectOption value="disliked">Disliked</IonSelectOption>
                <IonSelectOption value="neutral">Neutral</IonSelectOption>
                <IonSelectOption value="all">All</IonSelectOption>
            </IonSelect>
            {loading ?
                <LoadingGenie/>
                :
                filteredRecipes && Object.entries(filteredRecipes).map(([key, recipe]) => (
                    <RecipeCardPresentation key={recipe.recipeId} recipe={recipe} usersRecipe={false} /> 
                ))}

        </>
    )
}

export { BrowseRecipeCardsView }