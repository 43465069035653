import backendUrl from "../../../utils/api_connectors/backend";
import { Recipe } from "../../../utils/types";

// true for liked, false for disliked, null for unknown, undefined for all
async function getUsersRecipes(user_id: string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id})

    };
    const response = await fetch(backendUrl + 'retrieveUsersRecipes', requestOptions);
    
    var data = await response.json();
    return data;
}


async function getUsersMyRecipes(user_id: string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id})

    };
    const response = await fetch(backendUrl + 'retrieveUsersMyRecipes', requestOptions);
    
    var data = await response.json();
    return data;
}


async function getRecipesToClean(){
    const requestOptions = {
        method: 'GET',
        headers: { "content-type": "application/json" },

    };
    const response = await fetch(backendUrl + 'retrieveRecipesToClean', requestOptions);
    
    var data = await response.json();
    return data;
}

async function markRecipeCleaned(recipe_id:string,status:string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ recipe_id: recipe_id, status:status})

    };
    const response = await fetch(backendUrl + 'markRecipeCleaned', requestOptions);
    
    var data = await response.json();
    return data;

}


async function getRecipesToSwipe(user_id: string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id})

    };
    const response = await fetch(backendUrl + 'retrieveRecipesToSwipe', requestOptions);
    
    var data = await response.json();
    return data;
}

async function getRecipesToBrowse(skip: number, user_id: string, likedStatus:string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ skip: skip, user_id:user_id, likedStatus:likedStatus})

    };
    const response = await fetch(backendUrl + 'retrieveRecipesToBrowse', requestOptions);
    
    var data = await response.json();
    return data;
}

async function getRecipes(recipeTextSearch:string){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ "recipe_text_search":recipeTextSearch})

    };
    const response = await fetch(backendUrl + 'searchRecipeAutocomplete', requestOptions);
    
    var data = await response.json();
    return data.results as Recipe[];
}

export {markRecipeCleaned,getRecipesToClean,getRecipes, getUsersRecipes, getUsersMyRecipes, getRecipesToSwipe, getRecipesToBrowse}

