import backendUrl from "./api_connectors/backend";
import { Ingredient, OnboardingUserFormData, mealList } from "./types";

function generateNewMealsKeys(meals: mealList) {
    var meals2: mealList = {};
    var i = 0;
    for (let [key, value] of Object.entries(meals)) {
        var newKey = '' + (new Date()).getTime() + i;
        meals2[newKey] = value;
        i++;
    }
    return meals2;
}

async function saveToClipboard(toClip: string) {
    navigator.clipboard.writeText(toClip);
    //request to backend to notify feature has been used
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
    };
    const response = await fetch(backendUrl + 'copy', requestOptions)
    var data = await response.json();
}

// function getUnitWeight(units: unitType[], selectedUnit: string) {
//     var tempUnitInfo = units.find(unit => unit.value === selectedUnit);
//     if (tempUnitInfo) return tempUnitInfo['gram_weight'];
//     return 0;
// }

function getMacrosFromIngredientList(ingredients: any[]) {
    var macros = {
        protein: 0,
        carbs: 0,
        fat: 0,
        calories: 0
    };

    var tempUnitEquivalentWeight = 0;

    for (let ingredient of ingredients) {
        if (ingredient && ingredient.selectedUnit) {
            tempUnitEquivalentWeight = ingredient.selectedUnit.gram_weight
            macros['protein'] += ingredient.quantity * ingredient.proteinPer100g * tempUnitEquivalentWeight / 100;
            macros['carbs'] += ingredient.quantity * ingredient.carbsPer100g * tempUnitEquivalentWeight / 100;
            macros['fat'] += ingredient.quantity * ingredient.fatPer100g * tempUnitEquivalentWeight / 100;
            macros['calories'] += ingredient.quantity * ingredient.caloriesPer100g * tempUnitEquivalentWeight / 100;
        }
    }

    for (let key in macros) {
        macros[key as keyof typeof macros] = Number(macros[key as keyof typeof macros].toFixed(1));
    }

    return macros;
}

const recipeToString = (ingredients: Ingredient[]) => {
    var toReturn: string = '';
    if (ingredients !== undefined) {
        for (let ingredient of ingredients) {
            toReturn += ingredient['quantity'] + ' ' + ingredient['selectedUnit'].value + ' ' + ingredient['name'] + '\n'
        }
    }
    return toReturn;
}

function dateToString(date: Date) {
    // Get the user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Get the date string in the user's timezone
    const dateString = date.toLocaleDateString("en-US", { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' });
    // Format the date string to 'yyyy-mm-dd'
    const [month, day, year] = dateString.split('/');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

function stringToDate(date: string) {
    return new Date(date);

}

// //returns date object from string set at 00:00:00
// const stringToDate = (stringDate:string) => {
//     var date = new Date(stringDate);
//     date.setHours(0,0,0,0);
//     return date;
// }

function isValidDateFormat(dateString: string): boolean {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(dateString);
}


function estimateUserMaintenance(userData: OnboardingUserFormData) {

}

// Mifflin-St Jeor Equation
function computeBMR(userData: OnboardingUserFormData) {
    var tempBMR = 0;

    if (userData.gender == 'male') {
        tempBMR = 10 * userData.weight + 6.25 * userData.height - 5 * userData.age + 5;
    }
    else {
        tempBMR = 10 * userData.weight + 6.25 * userData.height - 5 * userData.age - 161;
    }
    return tempBMR;
}

function computeMaintenance(userData: OnboardingUserFormData) {
    var tempBMR = computeBMR(userData);
    var tempMaintenance: number = 0;
    switch (userData.activityLevel) {
        case 'sedentary':
            tempMaintenance = tempBMR * 1.2;
            break;
        case 'lightly active':
            tempMaintenance = tempBMR * 1.375;
            break;
        case 'moderately active':
            tempMaintenance = tempBMR * 1.55;
            break;
        case 'very active':
            tempMaintenance = tempBMR * 1.725;
            break;
        case 'extra active':
            tempMaintenance = tempBMR * 1.9;
            break;
    }
    return tempMaintenance;

}


function computeMacros(caloricTarget: number) {
    var to_ret = { 'protein': caloricTarget * 0.35 / 4, 'carbs': caloricTarget * 0.45 / 4, 'fat': caloricTarget * 0.2 / 9 }
    return to_ret;
}

function getTodaysDay() {
    // Get the current date
    const date = new Date();
    // Get the user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Get the date string in the user's timezone
    const dateString = date.toLocaleDateString("en-US", { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' });
    // Format the date string to 'yyyy-mm-dd'
    const [month, day, year] = dateString.split('/');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

function getDateRelative(delta: number) {
    const date = new Date();
    // Subtract 7 days from the current date
    date.setDate(date.getDate() + delta);
    return date;
}

function getMacrosFromMeals(meals: mealList | undefined) {
    var fullIngredientList: Ingredient[] = [];
    var sumMacros = { protein: 0, carbs: 0, fat: 0, calories: 0 };
    if (meals !== undefined) {
        for (let i = 0; i < Object.keys(meals).length; i++) {
            const meal = meals[Object.keys(meals)[i]];
            if (Array.isArray(meal?.ingredients)) {
                fullIngredientList = [...fullIngredientList, ...(meals[Object.keys(meals)[i]]['ingredients'] as Ingredient[])];
            }
        }
        var sumMacros = getMacrosFromIngredientList(fullIngredientList);
        // setSumMacros(prev=>sumMacros);
    }
    return sumMacros;
}



export {
    getMacrosFromMeals,
    isValidDateFormat,
    computeBMR,
    computeMaintenance,
    computeMacros,
    dateToString,
    getDateRelative,
    recipeToString,
    generateNewMealsKeys,
    getMacrosFromIngredientList,
    getTodaysDay,
    saveToClipboard,
    stringToDate
};