import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonIcon } from "@ionic/react"
import { Recipe } from "../../../utils/types"
import { heart, heartOutline, heartDislike } from 'ionicons/icons';
import { useContext, useEffect, useRef, useState } from "react";
import { reverse } from "lodash";
import { UserContext } from "../../../App";
import { updateRecipeLikes } from "../../../utils/db_connectors/database-connectors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useHistory } from "react-router";
import path from "path";
import { editLikeStatus } from "../../../redux/recipeSlice";

type recipeCardPresentationProps = {
    recipe: Recipe,
    disableLike?: boolean,
    usersRecipe: boolean
}


function RecipeCardPresentation(props: recipeCardPresentationProps) {
    const [likedStatus, setLikedStatus] = useState<boolean | null>(props.recipe.like !== null ? props.recipe.like : null);
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState('');
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);
    const [likedStatusChanged, setLikedStatusChanged] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (likedStatusChanged) {
            updateRecipeLikes(user!, [{ recipe_id: props.recipe.recipeId, likeStatus: likedStatus }]);
            dispatch(editLikeStatus({ recipeId: props.recipe.recipeId, likedStatus: likedStatus }));
            setLikedStatusChanged(prev => false);
        }
    }, [likedStatus]);



    function onClickLikeStatus() {

        switch (likedStatus) {
            case null:
                setLikedStatus(true);
                break;
            case true:
                setLikedStatus(false);
                break;
            case false:
                setLikedStatus(null);
                break;
            default:
                setLikedStatus(true);
                break;
        }
        setLikedStatusChanged(prev => true);
    }

    function onClickCard() {
        history.push({ 
            pathname: '/recipes/singleRecipeView/', 
            state: { key: props.recipe.recipeId, recipe: props.recipe, usersRecipe: props.usersRecipe, editMode: false } 
        });
    }

    return (
        <>
            <IonCard
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                onClick={onClickCard}
            >
                <div>
                    <img alt="Silhouette of mountains" src={props.recipe?.imageURL ? props.recipe?.imageURL : 'https://ionicframework.com/docs/img/demos/thumbnail.svg'} />
                    <IonCardHeader>
                        <IonCardTitle>{props.recipe.recipeName}</IonCardTitle>
                        {/* <IonCardSubtitle>{props.recipe.recipeId}</IonCardSubtitle> */}
                    </IonCardHeader>

                    {/* <IonCardContent>Here's a small text description for the card content. Nothing more, nothing less.</IonCardContent> */}
                </div>
                {!props.disableLike &&
                    <div style={{ alignSelf: 'flex-end', marginBottom: '10px', marginRight: '10px' }} onClick={(e) => {
                        e.stopPropagation(); onClickLikeStatus()
                    }}>
                        {(() => {
                            switch (likedStatus) {
                                case true:
                                    return <IonIcon icon={heart} color="danger" style={{ fontSize: '24px' }} />;
                                case false:
                                    return <IonIcon icon={heartDislike} color="dark" style={{ fontSize: '24px' }} />;
                                case null:
                                    return <IonIcon icon={heartOutline} color="medium" style={{ fontSize: '24px' }} />;
                                default:
                                    return <IonIcon icon={heartOutline} color="medium" style={{ fontSize: '24px' }} />;
                            }
                        })()}
                    </div>
                }
            </IonCard>
        </>
    )
}

export { RecipeCardPresentation }