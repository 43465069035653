import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import dayjs from "dayjs";
import { loadUserDayOfEating, writeUserDayOfEating } from "../../../utils/db_connectors/database-connectors";
import { Ingredient, OnboardingUserFormData, mealList, sumMacros } from "../../../utils/types";
import { MealPlanComponent } from "./MealPlanComponent";
import { Loading } from "../../../components/Loading/Loading";
import { useHistory, useParams } from "react-router-dom"; //useNavigate
import { dateToString, getMacrosFromIngredientList, getMacrosFromMeals, getTodaysDay, isValidDateFormat } from "../../../utils/misc-utils";
import { TourComponent } from "../../../components/TourComponent/TourComponent";
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { UserContext } from "../../../App";
import { generateMealPlan } from "../Services/mealplanServices";
import { useDispatch, useSelector } from "react-redux";
import { addMeal, fetchMealsSuccess } from "../../../redux/mealsSlice";
import { RootState } from "../../../redux/store";
import { GenerateMealPlanCard } from "./GenerateMealPlanCard/GenerateMealPlanCard";
import { FabButton } from "./FabButton/FabButton";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { addError } from "../../../redux/errorSlice";

type mealPlanPageProps = {
    user_id: string,
    day: string | null,
    userData: OnboardingUserFormData,
}



function MealPlanPage(props: mealPlanPageProps) {
    // const [meals, setMeals] = useState<mealList>();
    const [date, setDate] = useState<string>(props.day ? props.day : getTodaysDay());
    const meals: mealList | undefined = useSelector((state: RootState) => state.meals[date]);
    const [sumMacros, setSumMacros] = useState<sumMacros>({ calories: 0, protein: 0, carbs: 0, fat: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [date, setDate] = useState<string>(props.day ? props.day : getTodaysDay());
    const history = useHistory();
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    const [mealPlanned, setMealPlanned] = useState<boolean>(false);

    const dispatch = useDispatch();

    // const { param_day } = useParams<{ param_day: string }>();
    // var date = dayjs(param_day).toDate();

    // useEffect function to initialize day state variable with param_day or today's date
    useEffect(() => {
        if (meals === undefined || mealPlanned) { //if nothing is found in redux store
            setIsLoading(true);
            getMeals().then((mealsFromDB) => {
                if (mealsFromDB) { // if something is found in db then set it in redux store
                    dispatch(fetchMealsSuccess({ date: date, meals: mealsFromDB }))
                }
                else {
                    dispatch(fetchMealsSuccess({ date: date, meals: {} }))
                }
                setIsLoading(false);
            })
        }
    }, [date, dispatch, mealPlanned]);

    useEffect(() => {
        var temp = getMacrosFromMeals(meals);
        setSumMacros(prev => temp);
        // if (!isInitialLoading && date !== undefined && meals !== undefined && Object.keys(meals).length !==0) {
        //     writeUserDayOfEating(props.user_id, date, meals, temp); //temp and not sumMacros so it waits for the macros to get computed
        // }
    }, [meals])


    function getDayAfter(day: string) {
        // Parse the string back into a Date object
        var date = new Date(day);
        date.setDate(date.getDate() + 1);
        const formattedDate = dateToString(date);
        setDate(formattedDate);
        // setDate(prev => formattedDate);
    }

    function getDayBefore(day: string) {
        // Parse the string back into a Date object
        var date = new Date(day);
        date.setDate(date.getDate() - 1);
        const formattedDate = dateToString(date);
        setDate(formattedDate);

        // setDate(prev => formattedDate);
    }

    // const handleDateChange = (selectedDate: Date | null) => {
    //     if (selectedDate) {
    //         history.push('/mealplan/' + dateToString(selectedDate));
    //     }
    // };


    //initializing state by retrieving recipes and  ingredients from database using doe_id 
    const getMeals = async () => {
        setIsLoading(true);
        if (props.user_id != null && date !== null) {
            const res = await loadUserDayOfEating(props.user_id, date);
            setIsLoading(false);
            return res;
        }
        setIsLoading(false);
    }

    const onClickAddEmptyRecipe = () => {
        if (user) {
            var newMealRank = 0;
            if (meals !== undefined) {
                for (let key in meals) {
                    newMealRank = meals[key]['mealRank'] > newMealRank ? meals[key]['mealRank'] : newMealRank;
                }
                newMealRank = newMealRank + 1;
            }
            var newMeal = {
                ingredients: [],
                recipeId: '',
                mealName: '',
                mealRank: newMealRank,
                recipeName: 'New Recipe',
                source: '',
                sourceURL: ''
            }
            var newMealKey = (new Date()).getTime().toString();
            dispatch(addMeal({ date: date, mealID: newMealKey, meal: newMeal }));
        }
        else {
            //prompt signup modal
        }
    };



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ PaddingTop: 0 }}>
                    <Row justify='center' align='middle'>
                        <Col>
                            {/* <TourComponent /> */}
                        </Col>
                        <Col style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <Button onClick={() => getDayBefore(date)}>{'<'}</Button>
                        </Col>
                        <Col>

                            <DatePicker
                                value={dayjs(date)}
                                onChange={(value) => setDate(dateToString(value!.toDate()))}
                                placeholder="Select a date"
                            // style={{ width: 200}}
                            />
                        </Col>
                        <Col style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <Button onClick={() => getDayAfter(date)}>{'>'}</Button>
                        </Col>
                    </Row>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                {isLoading ? <LoadingGenie /> :

                    <MealPlanComponent
                        meals={meals}
                        //getMeals={getMeals}
                        // setMeals={setMeals}
                        isDoe={false}
                        date={date}
                        sumMacros={sumMacros}
                        setSumMacros={setSumMacros}
                    />
                }
                <GenerateMealPlanCard setIsLoading={setIsLoading} setMealPlanned={setMealPlanned} date={date} />
                <FabButton date={date} onClickAddEmptyRecipe={onClickAddEmptyRecipe}/>
            </IonContent>
        </IonPage>

    )

}

export { MealPlanPage };
