import { useContext, useEffect, useState } from "react";
import { Recipe } from "../../../utils/types";
import { RecipeCardWrapper } from "../../Onboarding/Components/RecipeSwipePage/RecipeCardWrapper";
import { UserContext } from "../../../App";
import { getRecipesToSwipe } from "../services/RecipePageServices";
import { updateRecipeLikes } from "../../../utils/db_connectors/database-connectors";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";

type SwipeRecipeViewProps = {
}

function SwipeRecipeView(props: SwipeRecipeViewProps) {
    const [recipeStack, setRecipeStack] = useState<Recipe[]>([]);
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);
    const [isLoading, setIsLoading] = useState<boolean>(true);
   

    useEffect(() => {
        setIsLoading(prev=>true);
        setRecipeStack(prev => []);
        initializeStack();
    }, []);

    async function initializeStack() {
        var temp = await getRecipesToSwipe(user!);
        setRecipeStack(prev => temp);
        setIsLoading(prev=>false);

    }


    function onSwipeLeft(recipeId: string) {
        updateRecipeLikes(user!, [{ recipe_id: recipeId, likeStatus: false }])
    }


    function onSwipeRight(recipeId: string) {
        updateRecipeLikes(user!, [{ recipe_id: recipeId, likeStatus: true }])
    }

    
    return (
        <>
        {isLoading && <LoadingGenie/>}
        {!isLoading && recipeStack.length === 0 ? <p>No Recipes left to swipe</p>
        :
            <RecipeCardWrapper recipeStack={recipeStack} onSwipeRight={onSwipeRight} onSwipeLeft={onSwipeLeft} />
        }
        </>
    )
}

export { SwipeRecipeView };