import { IonPage, IonContent, IonButton, IonButtons, IonBackButton, IonHeader, IonImg, IonText, IonTitle, IonToolbar, IonCol, IonGrid, IonRow } from "@ionic/react";
import logo from '../../../../assets/noun-genie-1637607-outlined.svg'; // replace with the path to your logo

type welcomeScreenComponentProps = {
    goToNextSlide: () => void;
}

function WelcomeScreenComponent(props:welcomeScreenComponentProps) {

    return (
        <IonPage>
            {/* <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/settings"></IonBackButton>
                </IonButtons>
                <IonTitle>Profile</IonTitle>
            </IonToolbar> */}

            <IonContent  className="ion-padding welcome-screen">
                {/* <IonContent>                 */}
                    <div className="content ion-text-center">
                    <h1 className="title">Nutrition Genie</h1>
                    <p>Tailored meal planning to reach your fitness goal</p>
                    <div className="logo-container">
                        <IonImg src={logo} alt="Logo" className="logo" />
                        {/* TODO Add swiperjs.com swipers with features */}
                    </div>
                </div>
                <div className="footer ion-text-center">
                    <IonButton expand="full" color="dark" className="start-button" shape="round" onClick={props.goToNextSlide}>Start</IonButton>
                    <IonText color="light">
                        Already have an account? <a href="/login">Login</a>
                    </IonText>
                </div>
            </IonContent>
            </IonPage>
    );
}

export { WelcomeScreenComponent }