import { Swiper as SwiperComponent, SwiperSlide, SwiperRef } from 'swiper/react';
import "swiper/css";
import "./swiper.css"; // Import your custom styles

// import required modules
import { Navigation } from 'swiper/modules';
import { IonPage, IonContent, IonButton } from "@ionic/react";
import { Swiper } from 'swiper/types';
import { useEffect, useState } from 'react';
import { Slide1Name } from './slide1Name';
import { Slide2MacroForm } from './slide2MacroForm';
import { OnboardingUserFormData } from '../../../../utils/types';
import ObjectId from "bson-objectid";
import { Slide5CreateAccount } from './slide5CreateAccount';
import { WelcomeScreenComponent } from './welcomeScreen';
import { RecipeSwipePage } from '../RecipeSwipePage/recipeSwipePage';
import { Slide3MacroResults } from './slide3macroResults';
import { DoePageComponent } from '../../../DoePage';
import { DoeStructurePage } from './DoeStructurePage';

// register Swiper custom elements

type OnBoardingSlidesProps = {
    onboardingFormData: OnboardingUserFormData | null;
    setOnboardingFormData: React.Dispatch<React.SetStateAction<OnboardingUserFormData>>;
    setUserIsOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
    setOnboardingRecipeLikes: React.Dispatch<React.SetStateAction<any[]>>;
}

function OnBoardingSlidesComponent(props: OnBoardingSlidesProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [swiper, setSwiper] = useState<Swiper | null>(null); // State to hold the Swiper instance

    // Function to navigate to the next slide
    const goToNextSlide = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    // Function to navigate to the previous slide
    const goToPrevSlide = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    return (
        // <IonPage className={"content"}>
        //     <IonContent>
                <SwiperComponent
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    onSlideChange={(swiper: Swiper) => setCurrentIndex(swiper.activeIndex)}
                    initialSlide={currentIndex}
                    onSwiper={(swiper: Swiper) => setSwiper(swiper)} // Save the Swiper instance
                    allowTouchMove={false}
                >
                    <SwiperSlide>
                        <WelcomeScreenComponent goToNextSlide={goToNextSlide} />
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="slide-content">
                            <Slide1Name
                                goToNextSlide={goToNextSlide}
                                goToPrevSlide={goToPrevSlide}
                                username={props.onboardingFormData?.username}
                                setOnboardingFormData={props.setOnboardingFormData}
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content">
                            {/* <h1>Slide 2</h1> */}
                            <Slide2MacroForm
                                goToNextSlide={goToNextSlide}
                                goToPrevSlide={goToPrevSlide}
                                setUserData={props.setOnboardingFormData}
                                userData={props.onboardingFormData!}
                            />

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-content">
                            <Slide3MacroResults
                                goToNextSlide={goToNextSlide}
                                goToPrevSlide={goToPrevSlide}
                                userData={props.onboardingFormData!}
                            />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        Allergies and diet
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <DoeStructurePage
                            goToNextSlide={goToNextSlide}
                            goToPrevSlide={goToPrevSlide}
                            setUserData={props.setOnboardingFormData}
                            />
                    </SwiperSlide>
                    <SwiperSlide>
                        <RecipeSwipePage
                            goToNextSlide={goToNextSlide}
                            goToPrevSlide={goToPrevSlide}
                            setOnboardingRecipeLikes={props.setOnboardingRecipeLikes}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Slide5CreateAccount 
                        setUserIsOnboarding={props.setUserIsOnboarding}
                        goToPrevSlide={goToPrevSlide} />
                    </SwiperSlide>

                    {/* Add more slides as needed */}
                </SwiperComponent>
            // </IonContent>
        // </IonPage>
    );
}


export { OnBoardingSlidesComponent }