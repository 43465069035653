import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { getAuth } from "firebase/auth";

async function isUserPremium(): Promise<boolean> {
    // var temp = getAuth().currentUser?.getIdToken(true);
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();
    return (decodedToken?.claims?.stripeRole == "premium");
}

async function isUserAdmin(): Promise<boolean> {
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();   
    return decodedToken?.claims?.admin? true : false;
}


// function usePremiumStatus(user: unknown) {
//     const [premiumStatus, setPremiumStatus] = useState<boolean>(false);

//     useEffect(() => {
//         if (user) {
//             const checkPremiumStatus = async function () {
//                 setPremiumStatus(await isUserPremium());
//             };
//             checkPremiumStatus();
//         }
//     }, [user]);

//     return premiumStatus;
// }


export { isUserPremium, isUserAdmin};//, usePremiumStatus };