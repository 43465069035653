import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UXState {
    swipeView: boolean;
    segment: string;
}

const initialState: UXState = {
    swipeView: true,
    segment: 'browse',
};

const uxSlice = createSlice({
    name: 'ux',
    initialState,
    reducers: {
        setSwipeView: (state, action: PayloadAction<boolean>) => {
            state.swipeView = action.payload;
        },
        changeSegment: (state, action: PayloadAction<string>) => {
            state.segment = action.payload;
        },
    },
});

export const { setSwipeView, changeSegment } = uxSlice.actions;

export default uxSlice.reducer;