import { IonPage, IonHeader, IonTitle, IonBackButton, IonButtons, IonToolbar, IonContent, IonInput, IonItem, IonLabel, IonButton, IonDatetime, IonModal } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Recipe, meal } from "../../../../utils/types";
import { addMeal } from "../../../../redux/mealsSlice";
import { useDispatch } from "react-redux";

type EditRecipeServingProps = {

}

function EditRecipeServingPage(props: EditRecipeServingProps) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [date, setDate] = useState<string>();
    const [mealID, setMealID] = useState<string>();
    const [recipe, setRecipe] = useState<Recipe>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [macros, setMacros] = useState({ calories: 0, protein: 0, carbs: 0, fat: 0 });

    const [showDatePicker, setShowDatePicker] = useState(false);



    function setServing(numServings: number) {
        setRecipe(prev => {
            if (prev) {
                return {
                    ...prev,
                    servings: numServings
                };
            }
        });

    }


    useEffect(() => {
        if (location.state && Object.keys(location.state as object).length > 0) {
            const { date, recipe: recipe } = location.state as { date: string, recipe: Recipe };
            setDate(date);
            setRecipe(recipe);
            history.replace({ state: {} });
            setIsLoading(prev => false);
        }
    }, [location]);


    useEffect(() => {
        if (recipe) {
            setMacros({
                calories: recipe.caloriesPerServing * recipe.servings,
                protein: recipe.proteinPerServing * recipe.servings,
                carbs: recipe.carbsPerServing * recipe.servings,
                fat: recipe.fatPerServing * recipe.servings
            });
        }
    }, [recipe]);

    function onClickAddRecipe() {
        var newMealKey = (new Date()).getTime().toString();
        dispatch(addMeal({ date: date!, mealID: newMealKey, meal: recipe }));
        history.push({ pathname: '/mealplan' });
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/mealplan/add-ingredient" />
                    </IonButtons>
                    <IonTitle>Edit recipe</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {isLoading && <IonLabel>Loading...</IonLabel>}
                {!isLoading && <>
                    <IonItem>
                        <IonLabel>Recipe name</IonLabel>
                        <IonInput value={recipe?.recipeName} readonly={true} class="ion-text-end" slot="end"></IonInput>
                    </IonItem>
                    <IonItem onClick={() => setShowDatePicker(true)}>
                        <IonLabel>Plan on</IonLabel>
                        <IonInput value={date} readonly={true} class="ion-text-end" slot="end"></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Servings</IonLabel>
                        <IonInput
                            type="number"
                            value={recipe?.servings}
                            onIonInput={e => setServing(((e.target as EventTarget) as HTMLInputElement).value as unknown as number)}
                            class="ion-text-end"
                            slot="end"
                        >

                        </IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Calories</IonLabel>
                        <IonInput value={macros.calories} readonly={true} class="ion-text-end" slot="end" disabled></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Protein</IonLabel>
                        <IonInput value={macros.protein} readonly={true} class="ion-text-end" slot="end" disabled></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Carbs</IonLabel>
                        <IonInput value={macros.carbs} readonly={true} class="ion-text-end" slot="end" disabled></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Fat</IonLabel>
                        <IonInput value={macros.fat} readonly={true} class="ion-text-end" slot="end" disabled></IonInput>
                    </IonItem>

                    <IonButton fill='clear' expand="full" onClick={onClickAddRecipe}>Add Recipe</IonButton>

                </>}
            </IonContent>
            <IonModal isOpen={showDatePicker}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Select a Date</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowDatePicker(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonDatetime
                        showDefaultButtons={true}
                        value={`${date}T00:00:00.000Z`}
                        onIonChange={e => {
                            const selectedDate = (e.detail.value as string).split('T')[0];
                            setDate(selectedDate);
                            setShowDatePicker(false);
                        }}
                        presentation="date"
                    ></IonDatetime>
                </IonContent>
            </IonModal>
        </IonPage>

    )
}

export { EditRecipeServingPage };

function dispatch(arg0: { payload: { date: string; mealID: string; meal: any; }; type: string; }) {
    throw new Error("Function not implemented.");
}
