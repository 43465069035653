import { Collapse, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function LoadingCardComponent() {
    const { Panel } = Collapse;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


    return (
        <>
            <Collapse defaultActiveKey={['1']} style={{minHeight:'100%'}}>
                <Panel header="Loading..." key="1">
                    <Row justify="center">
                        <Spin indicator={antIcon} />
                    </Row>
                </Panel>
            </Collapse>
        </>
    );
}

export default LoadingCardComponent;