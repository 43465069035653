import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { OnboardingUserFormData } from "../../../../utils/types";

type slide3MacroResultsProps = {
    userData: OnboardingUserFormData;
    goToNextSlide: () => void;
    goToPrevSlide: () => void;
}

function Slide3MacroResults(props: slide3MacroResultsProps) {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Macros
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <p>Here are your macros</p>
                <p>Maintenance: {props.userData.caloricMaintenance}</p>
                <p>Calories: {props.userData.caloricTarget}</p>
                {/* <p>Macros: {props.userData.macrosTarget}</p> */}
                <div className="button-container">

                    <IonButton shape="round" onClick={props.goToPrevSlide}>Prev</IonButton>
                    <IonButton shape="round" onClick={props.goToNextSlide}>Next</IonButton>
                </div>
            </IonContent>
        </IonPage>
    )
}

export { Slide3MacroResults };