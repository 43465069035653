import backendUrl from "../../../../utils/api_connectors/backend";


async function getRecipeFromURL(url:string) {
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({url:url})
    };
    const response = await fetch(backendUrl+'scrapeRecipeByURL', requestOptions)
    const data = await response.json();
    //console.log(data.raw_ingredient_list);
    return data;
}


async function getRecipeFromText(ingredients: string[]) {
    const requestOptions = {
        method: 'POST',
        headers: {"content-type":"application/json"},
        body: JSON.stringify({rawIngredients:ingredients})
    };
    const response = await fetch(backendUrl+'scrapeRecipeByText', requestOptions)
    const data = await response.json();
    //console.log(data.raw_ingredient_list);
    return data;

}

export { getRecipeFromURL, getRecipeFromText }