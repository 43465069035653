import { IonSearchbar, IonLabel, IonList, IonItem, IonButton, IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { Ingredient } from "../../../../utils/types";
import { useEffect, useState } from "react";
import { searchIngredientsUSDAAutocomplete } from "../../../../utils/db_connectors/database-connectors";
import { useDispatch } from "react-redux";
import { addIngredient } from "../../../../redux/mealsSlice";
import { useHistory, useLocation } from "react-router";
import { addIngredientToRecipe } from "../../../../redux/recipeSlice";
import { addError } from "../../../../redux/errorSlice";

type AddIngredientPageProps = {
    date?: string,
    mealID?: string,
    recipeID?: string,
    replacing?: string,
}

function AddIngredientPage(props: AddIngredientPageProps) {
    const location = useLocation();
    const [date, setDate] = useState<string>();
    const [mealID, setMealID] = useState<string>();
    const [recipeID, setRecipeID] = useState<string>();
    const [replacing, setReplacing] = useState<string>();

    useEffect(() => {

        if (location.state && Object.keys(location.state as object).length !== 0) {
            const { date, mealID, recipeID, replacing } = location.state as AddIngredientPageProps;
            setDate(date);
            setMealID(mealID);
            setRecipeID(recipeID);
            setReplacing(replacing);
            history.replace({ state: {} });
        }
    }, [location]);


    const [loadingDbResults, setLoadingDbResults] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [ingredientSearchResults, setIngredientSearchResults] = useState<Array<any>>([]);
    const [recentIngredients, setRecentIngredients] = useState<Ingredient[]>([]);
    const dispatch = useDispatch();
    const history = useHistory();


    const handleKeyDown = async (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            setLoadingDbResults(prev => true);
            // Perform your action here
            const res = await searchIngredientsUSDAAutocomplete(searchText);
            setIngredientSearchResults(prev => res.results);
            setLoadingDbResults(prev => false)
        }
    };


    useEffect(() => {
        const loadedIngredients = JSON.parse(localStorage.getItem('recentIngredients') || '[]');
        setRecentIngredients(loadedIngredients);
    }, []);


    useEffect(() => {
        if (searchText === '') {
            setIngredientSearchResults([]);
        }
    }, [searchText]);


    const handleQuickAddIngredient = (ingredient: Ingredient) => {
        if (date) {
            // dispatch(addIngredient)
            dispatch(addIngredient({ date: date!, mealID: mealID!, ingredient: { ...ingredient, key: (new Date()).getTime() } }));
            dispatch(addError({ id: (new Date()).getTime().toString(), errorMessage: ingredient.name+ ' added' }));
        }
        else {
            //TODO handlequick add for replacing ingredient in cleaning recipe
            dispatch(addIngredientToRecipe({...ingredient, key:(new Date()).getTime()}));

        }
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/mealplan" />
                    </IonButtons>
                    <IonTitle>Add food</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonSearchbar
                    value={searchText}
                    onKeyUp={e => setSearchText((e.target as HTMLInputElement).value)}
                    onKeyDown={handleKeyDown}
                >
                </IonSearchbar>

                <IonLabel><h1>History</h1></IonLabel>

                <IonList>
                    {recentIngredients.filter(ingredient => ingredient.name.toLowerCase().includes(searchText.toLowerCase())).map((ingredient: Ingredient, index) => (
                        <IonItem key={index}>
                            {ingredient.name},{ingredient.quantity},{ingredient.selectedUnit.value}
                            <IonButton slot="end" onClick={() => handleQuickAddIngredient(ingredient)}>
                                +
                            </IonButton>
                        </IonItem>
                    ))}
                </IonList>

                <IonLabel><h1>Db results</h1></IonLabel>

                {loadingDbResults && <IonLabel>Loading...</IonLabel>}

                {!loadingDbResults && ingredientSearchResults.length > 0 &&
                    <IonList>
                        {ingredientSearchResults.map((result, index) => (
                            <IonItem key={index} button onClick={() => {
                                history.push({
                                    pathname: `/mealplan/editIngredient/`,
                                    state: { date: date, mealID: mealID, recipeID: recipeID, fdcID: result.key, replacing: replacing}
                                })
                            }
                            }>
                                {result.value}
                            </IonItem>
                        ))}
                    </IonList>
                }
                {/* ${encodeURIComponent(result.value)}} */}

                {!loadingDbResults && ingredientSearchResults.length === 0 && <IonLabel>No results</IonLabel>}
            </IonContent>
        </IonPage>
    )
}

export { AddIngredientPage };