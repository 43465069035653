// EmailInput.js (child component)
import React, { useState, useEffect } from 'react';
import { IonInput } from '@ionic/react';

interface EmailInputProps {
  email: string;
  isValid: boolean;
  setEmail: (email: string) => void;
  setIsValid: (arg0: boolean) => void,
}

function EmailInput(props: EmailInputProps) {

  const [isTouched, setIsTouched] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    var temp = email.match(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    return temp;
  };

  const handleIonInput = (event: Event) => {
    const newValue = (event.target as HTMLInputElement).value;
    props.setEmail(newValue);
    validateEmail(newValue) !== null ? props.setIsValid(true) : props.setIsValid(false);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  // const handleIonBlur = () => {
  //   onBlur();
  // };

  return (
    <IonInput
      className={props.isValid ? 'ion-valid': 'ion-invalid'}
      type="email"
      fill="solid"
      label="Email"
      labelPlacement="floating"
      // helperText="Enter a valid email"
      errorText="Invalid email"
      value={props.email}
      onIonInput={handleIonInput}
      onIonBlur={() => markTouched()}
    // onIonBlur={handleIonBlur}
    ></IonInput>
  );
};

export { EmailInput };