import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonIcon, IonButton, IonButtons, IonLabel, IonSegment, IonSegmentButton, IonSearchbar, IonSelectOption, IonSelect } from "@ionic/react"
import { gridOutline, copyOutline } from 'ionicons/icons';
import { useEffect, useState } from "react";
import { RecipeCardWrapper } from "../Onboarding/Components/RecipeSwipePage/RecipeCardWrapper";
import { Recipe } from "../../utils/types";
import { loadOnboardingRecipes } from "../Onboarding/Services/onboardingServices";
import { Loading } from "../../components/Loading/Loading";
import { SwipeRecipeView } from "./components/SwipeRecipeView";
import { BrowseGallery } from "@mui/icons-material";
import { BrowseRecipeCardsView } from "./components/BrowseRecipeCardsView";
import { BrowseMyRecipesView } from "./components/BrowseMyRecipesView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { changeSegment, setSwipeView } from "../../redux/uxSlice";
import { CleanRecipes } from "./components/CleanRecipes";

type recipePageProps = {

}

function RecipePage(props: recipePageProps) {
    // const [swipeView, setSwipeView] = useState<boolean>(true);
    const swipeView = useSelector((state: RootState) => state.ux.swipeView);
    // const [unknownRecipesStack, setUnknownRecipesStack] = useState<Recipe[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [segment, setSegment] = useState('browse');
    const segment = useSelector((state: RootState) => state.ux.segment);

    const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);

    const [searchText, setSearchText] = useState<string>('');
    const [recipes, setRecipes] = useState<Recipe[]>([]);


    const handleSegmentChange = (event: CustomEvent) => {
        dispatch(changeSegment(event.detail.value));
        // setSegment(event.detail.value);
    };

    const dispatch = useDispatch();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Recipes</IonTitle>
                    <IonButtons slot="end">
                        {swipeView ?
                            <IonButton onClick={() => dispatch(setSwipeView(false))}>
                                <IonIcon icon={gridOutline} />
                            </IonButton>
                            :
                            <IonButton onClick={() => dispatch(setSwipeView(true))}>
                                <IonIcon icon={copyOutline} />
                            </IonButton>}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {swipeView ?
                    <>
                        <h1>Swipe view</h1>

                        <SwipeRecipeView />
                    </>
                    :
                    <>
                        <IonSegment value={segment} onIonChange={handleSegmentChange}>
                            <IonSegmentButton value="browse">
                                <IonLabel>Browse</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="liked">
                                <IonLabel>My recipes</IonLabel>
                            </IonSegmentButton>
                            {isAdmin &&
                            <IonSegmentButton value="clean">
                                <IonLabel>Clean</IonLabel>
                            </IonSegmentButton>
                            }
                        </IonSegment>

                        {segment === 'browse' &&
                        
                        <BrowseRecipeCardsView/> }

                        {segment === 'liked' &&
                        <BrowseMyRecipesView/>
                       }

                        {isAdmin && segment === 'clean' &&
                       <CleanRecipes/>
                       }


                    </>
                }

            </IonContent>
        </IonPage>
    )
};

export { RecipePage };