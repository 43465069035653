// store.ts
import { combineReducers } from 'redux';
import { configureStore, createAction, createReducer } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Ingredient, mealList } from '../utils/types';
import { getTodaysDay } from '../utils/misc-utils';
import { User } from 'firebase/auth';
import { produce } from 'immer';
import { RootState } from './store';
import { current } from '@reduxjs/toolkit';
import { logOut } from './authSlice';


const initialState = {} as { [date: string]: mealList };

// Define your actions
export const fetchMealsSuccess = createAction<{ date: string, meals: mealList }>('meals/fetchMealsSuccess');
export const addIngredient = createAction<{ date: string, mealID: string, ingredient: Ingredient }>('meals/addIngredient');
export const removeIngredient = createAction<{ date: string, mealID: string, ingredientKey: string }>('meals/removeIngredient');
export const editIngredient = createAction<{ date: string, mealID: string, ingredient: Ingredient }>('meals/editIngredient');
export const deleteRecipe = createAction<{ date: string, mealID: string }>('meals/deleteRecipe');
export const toggleIngredientLock = createAction<{ date: string, mealID: string, ingredientKey: number }>('meals/toggleIngredientLock');
export const addMeal = createAction<{ date: string, mealID: string, meal: any }>('meals/addMeal');
export const editRecipeName = createAction<{ date: string, mealID: string, recipeName: string }>('meals/editRecipeName');

// Define your reducer
const mealReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMealsSuccess, (state, action) => {
      state[action.payload.date] = action.payload.meals || {}; // If action.payload.meals is undefined, return an empty object
    })
    .addCase(addIngredient, (state: { [date: string]: mealList; }, action) => {
      const { date, mealID, ingredient } = action.payload;
      state[date][mealID].ingredients.push({ ...ingredient, key: (new Date()).getTime() });
    })
    .addCase(removeIngredient, (state: { [date: string]: mealList; }, action) => {
      const { date, mealID, ingredientKey } = action.payload;
      if (state[date] && state[date][mealID]) {
        state[date][mealID].ingredients = state[date][mealID].ingredients.filter((ingredient) => {
          return ingredient.key.toString() !== ingredientKey;
        });
      }
    })
    .addCase(editIngredient, (state: { [date: string]: mealList; }, action) => {
      const { date, mealID, ingredient } = action.payload;
      state[date][mealID].ingredients = state[date][mealID].ingredients.map((existingIngredient: Ingredient) => {
        if (existingIngredient.key === ingredient.key) {
          
          return ingredient;
        }
        return existingIngredient;
      });
    })
    .addCase(deleteRecipe, (state, action) => {
      const { date, mealID } = action.payload;
      
      const meals = state[date];
      if (meals) {
        const filteredMeals = Object.entries(meals)
          .filter(([key]) => key !== mealID)
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
    
        state[date] = filteredMeals;
      }
    })
    .addCase(toggleIngredientLock, (state, action) => {
      const { date, mealID, ingredientKey } = action.payload;
      state[date][mealID].ingredients = state[date][mealID].ingredients.map(ingredient => {
        if (ingredient.key === ingredientKey) {
          return { ...ingredient, locked: !ingredient.locked }
        }
        else {
          return ingredient;
        }
      });
    })
    .addCase(addMeal, (state, action) => {
      const { date, mealID, meal } = action.payload;
      if (!state[date]) {
        state[date] = {};
      }
      state[date][mealID] = meal; // Add the new meal to the state
    })
    .addCase(editRecipeName, (state: { [date: string]: mealList; }, action) => {
      const { date, mealID, recipeName } = action.payload;
      if (state[date] && state[date][mealID]) {
        state[date][mealID].recipeName = recipeName;
      }
    })
    .addCase(logOut, (state) => {
      // reset state
      return initialState;
    });
});

export { mealReducer };