import { Row, Col, Button, Spin } from "antd";
import { auth, } from "../../../../utils/firebase";
import { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { isUserPremium } from "../../../../utils/stripe_connectors/isUserPremium";
import { createCheckoutSession } from "../../../../utils/stripe_connectors/createCheckoutSession";
import { User } from "firebase/auth";
import { LoadingOutlined } from '@ant-design/icons';
import { IonBackButton, IonButton, IonButtons, IonContent, IonInput, IonItem, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { UserContext } from "../../../../App";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";



function ProfilePageComponent() {
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);
    const userEmail = useSelector((state: RootState) => state.auth.email);
    const isPremium = useSelector((state: RootState) => state.auth.isPremium);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            setLoading(false);
        }
    }, [user]);

    // const navigate = useNavigate();

    // var userName:string = user?.displayName? user?.displayName : '';
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <IonPage>

            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/settings"></IonBackButton>
                </IonButtons>
                <IonTitle>Profile</IonTitle>
            </IonToolbar>

            <IonContent>
                <IonItem>
                    <IonInput label="Email" value={auth.currentUser?.email} readonly={true}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput label="Plan" value={isPremium ? 'Premium' : 'Free'} readonly={true}></IonInput>
                </IonItem>
                {isPremium ?
                    <a href='https://billing.stripe.com/p/login/eVaaHkfQp2emaS4288'>
                        <IonButton expand="block">
                            Manage Plan
                        </IonButton>
                    </a>
                    :
                    <IonButton expand="block" /*onClick={() => createCheckoutSession(String(auth.currentUser?.uid))}*/>
                        Get Premium
                    </IonButton>}
                <IonButton expand="block" onClick={() => { return }}>   {/* //</Col>sendPasswordReset(userEmail)} block> */}
                    Reset Password
                </IonButton>


                <IonButton
                    onClick={() => (window.location.href = 'mailto:nutritiongenieapp@gmail.com')}
                    expand="block"
                >
                    Request account deletion
                </IonButton>
            </IonContent >
        </IonPage >

    );
}

export { ProfilePageComponent };