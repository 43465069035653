// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    // sendPasswordResetEmail,
    // signOut,
    AuthErrorCodes,
    signInWithCredential,
    EmailAuthProvider,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { FirebaseAuthentication, GetCurrentUserResult } from '@capacitor-firebase/authentication';
import { useEffect, useState } from "react";
import { addError } from "../redux/errorSlice";
import { Dispatch } from "redux";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDh6OUCE6KtQIkj3tth4pR5hWLpVn9YrIQ",
    authDomain: "nutritiongenie-auth-744ee.firebaseapp.com",
    projectId: "nutritiongenie-auth-744ee",
    storageBucket: "nutritiongenie-auth-744ee.appspot.com",
    messagingSenderId: "535385926639",
    appId: "1:535385926639:web:b3c330c00bb681529d241a",
    measurementId: "G-8QM3VT0KS0"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);


// const fetchUserName = async (uid: string) => {
//     try {
//         const q = query(collection(db, "users"), where("uid", "==", uid));
//         const doc = await getDocs(q);
//         const data = doc.docs[0].data();
//         return data.name;
//     } catch (err) {
//         console.error(err);
//         alert("An error occured while fetching user data");
//     }
// };

// // async function getRole(uid:string){
// //     if(auth && auth.currentUser){
// //         await auth.currentUser.getIdToken(true);
// //         const decodedToken = await auth.currentUser.getIdTokenResult();
// //         return decodedToken.claims.stripeRole;
// //     }
// //     return '';
// // }

// type FirebaseLoginAuthError =
//     | {
//         code: 'auth/invalid-email';
//         message: string;
//     }
//     | {
//         code: 'auth/user-not-found';
//         message: string;
//     }
//     | {
//         code: 'auth/wrong-password';
//         message: string;
//     }
//     | {
//         code: string; // Handle other Firebase Authentication errors
//         message: string;
//     };

// // Email Pwd auth
// const logInWithEmailAndPassword = async (email: string, password: string) => {
//     try {
//         // Attempt to log in with email and password
//         await signInWithEmailAndPassword(auth, email, password);
//         // Login successful, return null to indicate success
//         return null;
//     } catch (error: FirebaseLoginAuthError | any) {
//         // Handle Firebase Authentication errors
//         switch (error.code) {
//             case 'auth/invalid-email':
//                 return { code: 'auth/invalid-email', message: 'Invalid email address.' };
//             case 'auth/user-not-found':
//                 return { code: 'auth/user-not-found', message: 'User not found.' };
//             case 'auth/wrong-password':
//                 return { code: 'auth/wrong-password', message: 'Incorrect password.' };
//             default:
//                 return { code: error.code, message: 'Login error.' };
//         }
//     }
// };


// const logout = () => {
//     signOut(auth);
// };

// type FirebaseAuthError = {
//     code: string;
//     message: string;
// };
// // Email Pwd Signup
// const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
//     try {
//         const res = await createUserWithEmailAndPassword(auth, email, password);
//         const user = res.user;
//         await addDoc(collection(db, "users"), {
//             uid: user.uid,
//             name,
//             authProvider: "local",
//             email,
//         });
//     } catch (error: FirebaseAuthError | any) {
//         switch (error.code) {
//             case 'auth/email-already-in-use':
//                 return {
//                     code: 'auth/email-already-in-use',
//                     message: 'The email address is already in use by another account.',
//                 };
//             // case 'auth/invalid-email':
//             //     return {
//             //         code: 'auth/invalid-email',
//             //         message: 'The email address is invalid.',
//             //     };
//             // case 'auth/weak-password':
//             //     return {
//             //         code: 'auth/weak-password',
//             //         message: 'The password is too weak. Please use a stronger password.',
//             //     };
//             default:
//                 // Handle other errors and return the error
//                 return {
//                     code: error.code,
//                     message: 'Registration error: ' + error.message,
//                 };
//         }        //   alert(err.message);
//     }
// };


// //Reset pwd
// const sendPasswordReset = async (email: string) => {
//     try {
//         await sendPasswordResetEmail(auth, email);
//         alert("Password reset link sent!");
//     } catch (error: any) {
//         // Handle Firebase Authentication errors and return the error
//         console.log(error);

//     };
// }

const sendPasswordResetEmail = async (email: string, dispatch:Dispatch) => {
    try {
        await FirebaseAuthentication.sendPasswordResetEmail({
            email: email,
        });
        dispatch(addError({ id: new Date().getTime().toString(), errorMessage: 'Password reset link sent!' }));
        // alert("Password reset link sent!");
    } catch (error: any) {
        // Handle Firebase Authentication errors and return the error
        dispatch(addError({ id: new Date().getTime().toString(), errorMessage: error.message }));
    }
};



const signInWithEmailAndPasswordBothLevels = async (email: string, password: string, dispatch: Dispatch) => {
    try {
        const result = await FirebaseAuthentication.signInWithEmailAndPassword({
            email: email,
            password: password,
        });

        const auth = getAuth();
        const credential = EmailAuthProvider.credential(email, password);
        await signInWithCredential(auth, credential);

        return result.user;
    }
    catch (error) {
        if (error instanceof Error) {
            dispatch(addError({ id: new Date().getTime().toString(), errorMessage: error.message }));
        } else {
            dispatch(addError({ id: new Date().getTime().toString(), errorMessage: 'An unknown error occurred' }));
        }
        console.error(error);
        // throw error;
        return error;
    }
}


const signInWithGoogle = async () => {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle();
    // 2. Sign in on the web layer using the id token
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    const auth = getAuth();
    await signInWithCredential(auth, credential);
  };
  

const signOut = async () => {
    await FirebaseAuthentication.signOut();
    await auth.signOut();
};


const createUserWithEmailAndPassword = async (email: string, password: string) => {
    try {
        // Create user on the native layer
        const nativeResult = await FirebaseAuthentication.createUserWithEmailAndPassword({
            email: email,
            password: password,
        });

        // Sign in the newly created user on the web layer
        await signInWithEmailAndPassword(auth, email, password);

        // Return the user from the native layer
        return nativeResult.user;
    } catch (error) {
        console.error(error);
        throw error;
    }
};



export { auth, 
    db, 
    signInWithEmailAndPasswordBothLevels, 
    createUserWithEmailAndPassword, 
    sendPasswordResetEmail,
    signInWithGoogle, 
    signOut };