import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from "@ionic/react"

type SevenDayCaloricInputAverageCardProps = {
    sevenDayAverage: number 

}

function SevenDayCaloricInputAverageCard(props:SevenDayCaloricInputAverageCardProps) {
  
  return(
    <IonCard >
            <IonCardHeader>
                <IonCardTitle>7-day-average caloric input</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {props.sevenDayAverage}
            </IonCardContent>
        </IonCard>
  )
}

export {SevenDayCaloricInputAverageCard}