import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton, IonContent, IonSegment, IonSegmentButton, IonLabel, IonTextarea, IonInput, IonItem, IonButton } from "@ionic/react";
import { useEffect, useState } from "react";
import { getRecipeFromText, getRecipeFromURL } from "./ImportRecipeServices";
import { useHistory } from "react-router";
import { PropaneSharp } from "@mui/icons-material";
import { LoadingGenie } from "../../../../components/LoadingGenie/LoadingGenie";


type importRecipePageProps = {

}

function ImportRecipePage() {

    const [segment, setSegment] = useState<string>('url');
    const [url, setUrl] = useState<string>('');
    const [textInput, setTextInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const history = useHistory();

    function onSegmentChange(e: CustomEvent) {
        setSegment(e.detail.value);
    }

    async function onClickNextTextInput(){
        setIsLoading(prev => true);
        var recipe_data = await getRecipeFromText(textInput.split('\n'));
        setTextInput('');
        history.push({pathname:'/mealplan/finalize-recipe-import/',state:{recipe:recipe_data, editMode:true, usersRecipe:true, isImport:true}});
        setIsLoading(prev => false);
    }

    async function onClickNextUrlInput(){
        setIsLoading(prev => true);
        var recipe_data = await getRecipeFromURL(url);
        setUrl('');
        history.push({pathname:'/mealplan/finalize-recipe-import/',state:{recipe:recipe_data, editMode:true, usersRecipe:true, isImport:true}});
        setIsLoading(prev => false);

    }

    const [isValidUrl, setIsValidUrl] = useState(true);

    const validateUrl = (url: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    useEffect(() => {
        setIsValidUrl(validateUrl(url));
    }, [url]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Import recipe</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonSegment value={segment} onIonChange={onSegmentChange}>
                    <IonSegmentButton value="url">
                        <IonLabel>From URL</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="text">
                        <IonLabel>From text</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {!isLoading && segment === 'url' &&
                    <>
                        <IonItem>
                            <IonInput
                                label="Enter URL"
                                labelPlacement="floating"
                            type="url"
                                value={url}
                            onIonChange={e => setUrl(e.detail.value ? e.detail.value : '')}
                            color={isValidUrl ? '' : 'danger'}
                        ></IonInput>
                        </IonItem>
                        <IonButton onClick={onClickNextUrlInput} disabled={!isValidUrl}>Next</IonButton>
                    </>
                }

                {!isLoading && segment === 'text' &&
                    <>
                        <IonItem>
                            <IonTextarea
                                label="Enter ingredients"
                                labelPlacement="floating"
                                placeholder="Type something here"
                                autoGrow={true}
                                value={textInput}
                                onIonChange={e => setTextInput(e.detail.value ? e.detail.value : '')}
                            >
                            </IonTextarea>
                        </IonItem>
                        <IonButton onClick={onClickNextTextInput}>Next</IonButton>
                    </>
                }

                {isLoading &&
                    <LoadingGenie />
                }


            </IonContent>
        </IonPage >
    )
}

export { ImportRecipePage };