import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonSearchbar, IonLabel, IonList, IonItem, IonButton, IonToggle } from "@ionic/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { addIngredient } from "../../../../redux/mealsSlice";
import { searchIngredientsUSDAAutocomplete } from "../../../../utils/db_connectors/database-connectors";
import { Ingredient, Recipe, mealList } from "../../../../utils/types";
import { getRecipes, getRecipesToBrowse, getUsersMyRecipes } from "../../../RecipePage/services/RecipePageServices";
import { RootState } from "../../../../redux/store";
import { setLoadedDbRecipes, setLoadedUsersRecipes } from "../../../../redux/recipeSlice";
import { search } from "ionicons/icons";

type AddRecipePageProps = {
    date: string,
    meals: mealList
}

function AddRecipePage(props: AddRecipePageProps) {

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [date, setDate] = useState<string>();
    const [meals, setMeals] = useState<mealList>();
    const [filterLiked, setFilterLiked] = useState<boolean>(true);
    const [loadingDbResults, setLoadingDbResults] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [recipeSearchResults, setRecipeSearchResults] = useState<Array<Recipe>>([]);
    const [recentRecipes, setRecentRecipes] = useState<Recipe[]>([]);

    //get recipes from the store
    const recipes = useSelector((state: RootState) => state.recipe.recipes);
    // boolean to track whether users recipes are already loaded in the store
    const loadedUsersRecipes = useSelector((state: RootState) => state.recipe.loadedUsersRecipes);
    // boolean to track whether db recipes have been loaded 
    const loadedDbRecipes = useSelector((state: RootState) => state.recipe.loadedDbRecipes);
    const [usersRecipes, setUsersRecipes] = useState<Recipe[]>([]);
    const [nonUsersRecipes, setNonUsersRecipes] = useState<Recipe[]>([]);



    const [loading, setLoading] = useState(false);
    const user = useSelector((state: RootState) => state.auth.userUid);

    useEffect(() => {
        if (location.state) {
            const { date, meals } = location.state as AddRecipePageProps;
            setDate(date);
            setMeals(meals);
            history.replace({ state: {} });
        }
    }, []);

    //get local history of recipes
    // useEffect(() => {
    //     const recentRecipes = JSON.parse(localStorage.getItem('recentRecipes') || '[]');
    //     setRecentRecipes(recentRecipes);

    //     async function loadRecipes() {
    //         const recipes = await getRecipes(searchText);
    //         setRecipeSearchResults(prev => recipes);
    //     }
    //     loadRecipes();

    // }, []);

    //retrieve other recipes 


    async function initializeStack() {
        if (!loadedDbRecipes) {
            setLoading(true);
            var temp: Recipe[] = [];
            // if (props.segment == "browse") {
            temp = await getRecipesToBrowse(0, user!, '')
            dispatch({ type: 'recipe/addRecipesToStore', payload: [temp, false] });
            // }
            // else if (props.segment == "liked") {
            //     temp = await getUsersRecipes(user!);
            // }
            // setRecipeStack(temp);
            dispatch(setLoadedDbRecipes(true));
            setLoading(false);
        }
        if (!loadedUsersRecipes) {
            setLoading(true);
            var temp: Recipe[] = [];
            // if (props.segment == "browse") {
            temp = await getUsersMyRecipes(user!);
            dispatch({ type: 'recipe/addRecipesToStore', payload: [temp, true] });
            // }
            // else if (props.segment == "liked") {
            //     temp = await getUsersRecipes(user!);
            // }
            // setRecipeStack(temp);
            dispatch(setLoadedUsersRecipes(true));
            setLoading(false);
        }
    }

    useEffect(() => {
        initializeStack();
    }, [])

    // const [usersRecipes, setUsersRecipes] = useState<Recipe[]>([]);
    // const [nonUsersRecipes, setNonUsersRecipes] = useState<Recipe[]>([]);
   
    useEffect(() => {
        const filteredUsersRecipes = recipes
            ? Object.values(recipes)
                .filter(({ usersRecipe, recipe }) => 
                    usersRecipe && 
                    (searchText === '' || recipe.recipeName.toLowerCase().includes(searchText.toLowerCase()))
                )
                .map(({ recipe }) => recipe)
            : [];
        setUsersRecipes(filteredUsersRecipes);

        const filteredNonUsersRecipes = recipes
            ? Object.values(recipes)
                .filter(({ usersRecipe, recipe }) => 
                    !usersRecipe && 
                    (searchText === '' || recipe.recipeName.toLowerCase().includes(searchText.toLowerCase())) &&
                    (filterLiked ? filterLiked === recipe.like : true)
                )
                .map(({ recipe }) => recipe)
            : [];
        setNonUsersRecipes(filteredNonUsersRecipes);
    }, [recipes, searchText, filterLiked]);



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="#" />
                    </IonButtons>
                    <IonTitle>Add recipe</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom:10 }}>
                    <div style={{ flex: '1' }}>
                        <IonSearchbar
                            style={{ width: '100%' }}
                            value={searchText}
                            onKeyUp={e => setSearchText((e.target as HTMLInputElement).value)}
                            onIonClear={() => setSearchText('')}

                        // onKeyDown={handleKeyDown}
                        >
                        </IonSearchbar>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', }}>
                        <IonToggle
                            checked={filterLiked}

                            enableOnOffLabels={true}
                            onIonChange={e => setFilterLiked(prev=>e.detail.checked)}
                        >
                            Liked
                        </IonToggle>
                    </div>
                </div>
                <IonLabel><h1>My recipes</h1></IonLabel>

                <IonList>
                    {usersRecipes.map((result, index) => (
                        <IonItem
                            key={index}
                            button
                            onClick={() => {
                                history.push({
                                    pathname: `/mealplan/editRecipe/`,
                                    state: { date: date, recipe: result }
                                })
                            }
                            }
                        >
                            <IonLabel>
                                <h2>{result.recipeName}</h2>
                                <p>{result.proteinPerServing} g protein, {result.carbsPerServing} g carbs, {result.fatPerServing} g fat, {result.caloriesPerServing} calories</p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>

                <IonLabel><h1>Others</h1></IonLabel>

                {loadingDbResults && <IonLabel>Loading...</IonLabel>}

                {/* {!loadingDbResults && recipeSearchResults.length > 0 && */}
                    <IonList>
                    {nonUsersRecipes.map(recipe => (
                            <IonItem
                                key={recipe.recipeId}
                                button
                                onClick={() => {
                                    history.push({
                                        pathname: `/mealplan/editRecipe/`,
                                        state: { date: date, recipe: recipe }
                                    })
                                }
                                }
                            >
                                <IonLabel>
                                    <h2>{recipe.recipeName}</h2>
                                    <p>{recipe.proteinPerServing} g protein, {recipe.carbsPerServing} g carbs, {recipe.fatPerServing} g fat, {recipe.caloriesPerServing} calories</p>
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>

            </IonContent>
        </IonPage>
    )

}

export { AddRecipePage }