import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { removeError } from "../../../../redux/errorSlice";

type ErrorDisplayProps = {
}

function ErrorDisplay(props: ErrorDisplayProps) {

    const errors = useSelector((state: RootState) => state.error);
    const dispatch = useDispatch();

    const handleDismiss = (errorId: string) => {
        dispatch(removeError(errorId));
    }

    return (
        <>
            {errors ? errors.map((error,index) =>
                <IonToast
                    position="bottom"
                    positionAnchor="tabs"
                    isOpen={true}
                    message={error.errorMessage}
                    duration={3000+3000*index}
                    buttons={[
                        {
                            text: 'Dismiss',
                            role: 'cancel',
                            handler: () => handleDismiss(error.id),
                        },
                    ]}
                    onDidDismiss={() => handleDismiss(error.id)}
                >
                </IonToast>)
        :
                null}
        </>
    );
};

export { ErrorDisplay };