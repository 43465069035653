import { Row, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';


const Loading = () =>{
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return(
        <Row justify='space-around' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <Spin indicator={antIcon} />
            </Row>
    );
}

export {Loading};