import { Alert, Button, Col, Form, Input, Row } from "antd";
import { SendOutlined } from '@ant-design/icons';
import backendUrl from "../../../../utils/api_connectors/backend";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../utils/firebase";
import { useState } from "react";
import { IonPage, IonContent, IonBackButton, IonButtons, IonTitle, IonToolbar } from "@ionic/react";


type AlertData = {
    id: number;
    type: 'success' | 'error';
    message: string;
};

function SupportPageComponent() {

    const [form] = Form.useForm();
    const [user, loading, error] = useAuthState(auth);
    const [alerts, setAlerts] = useState<AlertData[]>([]);
    const hideAlert = (id: number) => {
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
    };


    const onFinish = async (values: any) => {
        try {
            // Send form data to the backend
            const response = await fetch(`${backendUrl}/mailSupport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (response.ok) {
                const id = Date.now();
                const type: 'success' | 'error' = 'success'
                const message: string = 'Your email has been sent to our support. You will also receive a copy at the email adress you provided.'
                const newAlert: AlertData = { id, type, message };

                // Add the new alert to the list of alerts
                setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
                // message.success('Message sent successfully');
                form.resetFields();
            } else {
                const id = Date.now();
                const type: 'success' | 'error' = 'error'
                const message: string = 'An error occured sending the email. We will look into this. In the meantime we remain available through our socials.'
                const newAlert: AlertData = { id, type, message };
                // message.error('Failed to send message');
            }
        } catch (error) {
            const id = Date.now();
            const type: 'success' | 'error' = 'error'
            const message: string = 'An unknown error occured. We will look into this. In the meantime we remain available through our socials.'
            const newAlert: AlertData = { id, type, message };
        }
    };

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    return (
        <IonPage>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/settings"></IonBackButton>
                </IonButtons>
                <IonTitle>Support</IonTitle>
            </IonToolbar>
            <IonContent>
            {alerts.map((alert) => (
                <Row>
                    <Alert
                        key={alert.id}
                        message={alert.type === 'success' ? 'Success' : 'Error'}
                        description={alert.message}
                        type={alert.type}
                        showIcon
                        closable
                        onClose={() => hideAlert(alert.id)}
                    />
                </Row>
            ))}
            <Row justify='center' style={{ marginTop: '40px' }}>
                <Col span={20}>
                    <Form
                        {...layout}
                        form={form}
                        name="contact-form"
                        onFinish={onFinish}
                        initialValues={{
                            name: user ? user.displayName : '',
                            email: user ? user.email : '',
                        }}
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter your name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                { required: true, message: 'Please enter your email' },
                                { type: 'email', message: 'Invalid email address' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Message"
                            rules={[{ required: true, message: 'Please enter your message' }]}
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Row justify='center'>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<SendOutlined />}
                                    // style={{ float: 'right' }}
                                >
                                    Send Message
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                    {/* </div> */}
                </Col>
            </Row >
            </IonContent>
            </IonPage>
            //name email message
    );
}


export { SupportPageComponent };