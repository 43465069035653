import { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, IonToggle, IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { useState } from "react";
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { isIP } from "net";
import { OnboardingUserFormData, goal2deficit } from "../../../../utils/types";
import { computeBMR, computeMacros, computeMaintenance } from "../../../../utils/misc-utils";
import ObjectId from "bson-objectid";



type slide2MacroProps = {
    goToNextSlide: () => void;
    goToPrevSlide: () => void;
    setUserData: React.Dispatch<React.SetStateAction<OnboardingUserFormData>>;
    userData: OnboardingUserFormData;
}

function Slide2MacroForm(props: slide2MacroProps) {
    // const [isMetric, setIsMetric] = useState(true);


    const [isUncompleteFormAlertOpen, setIsUncompleteFormAlertOpen] = useState(false);

    const handleInputChange = (key: keyof OnboardingUserFormData, value: string | number | {}) => {
        props.setUserData(prevData => ({
            ...prevData,
            [key]: typeof value === 'number' ? Math.round(value) : value
        } as OnboardingUserFormData));
    };

    const handleClickNext = () => {
        const isFormEmpty = Object.values(props.userData).some(value => value === '');

        if (isFormEmpty) {
            setIsUncompleteFormAlertOpen(true);
            return;
        }
        var tempMaintenance = computeMaintenance(props.userData);
        handleInputChange('caloricMaintenance', tempMaintenance);
        var caloricGoal = tempMaintenance + goal2deficit[props.userData.goal as keyof typeof goal2deficit];
        handleInputChange('caloricTarget', caloricGoal);
        var tempMacrosTarget = computeMacros(caloricGoal);
        handleInputChange('macrosTarget', tempMacrosTarget);
        props.goToNextSlide();
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>User info</IonTitle>
                </IonToolbar>
            </IonHeader>
            {/* <IonItem>
                <IonToggle checked={formData.isMetric} onClick={toggleMetric}>Metric</IonToggle>
            </IonItem> */}
            <IonContent>
                <IonItem>
                    <IonLabel>Age</IonLabel>
                    <IonInput
                        type="number"
                        placeholder="Enter Age"
                        value={props.userData.age}
                        onIonChange={e => handleInputChange('age', e.detail.value!)}
                        required
                        class='ion-text-end'
                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Gender</IonLabel>
                    <IonSelect
                        placeholder="Select One"
                        value={props.userData.gender}
                        onIonChange={e => handleInputChange('gender', e.detail.value)}
                    >
                        <IonSelectOption value="male">Male</IonSelectOption>
                        <IonSelectOption value="female">Female</IonSelectOption>
                        <IonSelectOption value="other">Other</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>Height (cm)</IonLabel>
                    <IonInput
                        type="number"
                        placeholder={'Enter Height (cm)'}
                        value={props.userData.height}
                        onIonChange={e => handleInputChange('height', e.detail.value!)}
                        required
                        class='ion-text-end'

                    />
                </IonItem>
                <IonItem>
                    <IonLabel>Weight (kg)</IonLabel>
                    <IonInput
                        type="number"
                        placeholder="Enter Weight"
                        value={props.userData.weight}
                        onIonChange={e => handleInputChange('weight', e.detail.value!)}
                        required
                        class='ion-text-end'

                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel>Activity Level</IonLabel>
                    <IonSelect
                        placeholder="Select One"
                        value={props.userData.activityLevel}
                        onIonChange={e => handleInputChange('activityLevel', e.detail.value)}
                    >
                        <IonSelectOption value="sedentary">Sedentary (little to no exercise)</IonSelectOption>
                        <IonSelectOption value="lightly active">Lightly active (light exercise/sports 1-3 days a week)</IonSelectOption>
                        <IonSelectOption value="moderately active">Moderately active (moderate exercise/sports 3-5 days a week)</IonSelectOption>
                        <IonSelectOption value="very active">Very active (hard exercise/sports 6-7 days a week)</IonSelectOption>
                        <IonSelectOption value="extra active">Extra active (very hard exercise/sports & physical job or training twice a day)</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>Goal</IonLabel>
                    <IonSelect
                        placeholder="Select One"
                        value={props.userData.goal}
                        onIonChange={e => handleInputChange('goal', e.detail.value)}
                    >
                        <IonSelectOption value="extraLoss">Extreme weightloss (-1kg/week)</IonSelectOption>
                        <IonSelectOption value="loss">Weightloss (-500g/week)</IonSelectOption>
                        <IonSelectOption value="mildLoss">Mild weightloss (-250g/week)</IonSelectOption>
                        <IonSelectOption value="maintain">Maintenance</IonSelectOption>
                        <IonSelectOption value="mildgain">Mild weight gain (+250g/week)</IonSelectOption>
                        <IonSelectOption value="gain">Weight gain (+500g/week)</IonSelectOption>
                        <IonSelectOption value="extremeGain">Extreme weight gain (+1kg/week)</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <div className="button-container">

                    <IonButton shape="round" onClick={props.goToPrevSlide}>Prev</IonButton>
                    <IonButton shape="round" onClick={handleClickNext}>Next</IonButton>
                </div>
                <IonAlert
                    isOpen={isUncompleteFormAlertOpen}
                    header="Missing values"
                    // subHeader="A Sub Header Is Optional"
                    message="Please fill in all fields so we can best help you."
                    buttons={['Ok']}
                    onDidDismiss={() => setIsUncompleteFormAlertOpen(false)}
                ></IonAlert>
            </IonContent>
        </IonPage>
    );
}

export { Slide2MacroForm };



