import { Form, Input, Button, Row, Col, Alert } from "antd";
// import { auth, registerWithEmailAndPassword, signInWithGoogle } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import { auth, createUserWithEmailAndPassword, signInWithGoogle } from "../../../../utils/firebase";
import { InputChangeEventDetail, IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { logoGoogle, mail } from "ionicons/icons";
import { EmailInput } from "../../../../components/EmailInput/EmailInput";
// import { useNavigate } from "react-router-dom";

type signUpPageComponentProps = {
    setIsUserOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
    isUserOnboarding: boolean;
}

function SignupPageComponent(props: signUpPageComponentProps) {

    const [user, loading, error] = useAuthState(auth);
    // const navigate = useNavigate();
    const [errorList, setErrorList] = useState<Array<String>>([]); // used to display errors catched to user

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // useEffect(() => {
    //     if (loading) {
    //         // maybe trigger a loading screen
    //         return;
    //     }
    //     if (user) {
    //         // navigate("/dashboard");
    //     }
    // }, [user, loading]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        },
    };
    /* eslint-enable no-template-curly-in-string */

    async function onFinish(values: any) {
        props.setIsUserOnboarding(true);
        try {
            await createUserWithEmailAndPassword(email, password);
            // navigate to dashboard or do something else
        } catch (error) {
            var err = error as Error;
            console.log('ERROR SIGNING UP', err);
            setErrorList([...errorList, err.message]);
        }
    };

    // useEffect(() => {
    //     if (loading) return;
    //     if (user) console.log('user authenticated ' + user);
    // }, [user, loading]);

    const onCloseError = (e: String) => {
        setErrorList([...errorList].splice(errorList.indexOf(e), 1));
    };

    const validatePassword = (_: any, value: string) => {
        if (value && value.length < 6) {
            return Promise.reject('Password must be at least 6 characters long');
        }
        return Promise.resolve();
    };

    const handleEmailChange = (event: CustomEvent<InputChangeEventDetail>) => {
        setEmail(event.detail.value!);
    };

    const handlePasswordChange = (event: CustomEvent<InputChangeEventDetail>) => {
        setPassword(event.detail.value!);
    };

    return (
       
            <>
                {errorList.length > 0 && errorList.map(error => {
                    return (
                        <Row>
                            <Alert message={error} type="error" style={{ width: '100%' }} closable onClose={() => onCloseError(error)} />
                        </Row>
                    )
                })}
                <div style={{ textAlign: 'center' }}>
                    <h2>
                        Don't have an account ? Sign up !
                    </h2>
                </div>
                <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput type="email" value={email} onIonChange={handleEmailChange} required></IonInput>
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput type="password" value={password} onIonChange={handlePasswordChange} required></IonInput>
                </IonItem>

                <IonButton
                    expand='block'
                    onClick={onFinish}
                >
                    SIGN UP WITH EMAIL</IonButton>
                <IonButton
                    onClick={() => {
                        props.setIsUserOnboarding(props.isUserOnboarding);
                        signInWithGoogle()
                    }}
                    expand='block'
                >
                    Sign up with google
                </IonButton>

           </>

    )
}

export default SignupPageComponent;