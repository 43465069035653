import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { writeUserWeight } from '../features/DashboardPage/Services/dashboardServices';

interface WeightEntry {
  date: string;
  weight: number;
}

interface DashboardState {
  weightEntries: Record<string, number>;
}

const initialState: DashboardState = {
  weightEntries: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    addWeightEntry: (state, action: PayloadAction<WeightEntry>) => {
      state.weightEntries[action.payload.date] = action.payload.weight;
    },
  },
});

export const { addWeightEntry } = dashboardSlice.actions;

export type {WeightEntry, DashboardState};

export default dashboardSlice.reducer;