import { addError } from '../../redux/errorSlice';
import backendUrl from '../api_connectors/backend';
import { Ingredient, OnboardingUserFormData, mealList, sumMacros } from '../types';


async function getRecipeInfo(recipe_id: number) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ recipe_id: recipe_id })
    };
    const response = await fetch(backendUrl + 'searchRecipeById', requestOptions);
    var data = response.json();
    return data;
}

async function getUSDAIngredientUnits(fdc_id: number) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ fdc_id: fdc_id })
    };
    const response = await fetch(backendUrl + 'searchUnitsUSDAbyId', requestOptions);
    var data = response.json();
    return data;
}

async function getUSDAIngredientMacros(fdc_id: number) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ fdc_id: fdc_id })
    };
    const response = await fetch(backendUrl + 'searchIngredientUSDAbyId', requestOptions);
    var data = response.json();
    return data;
}


async function updateRecipeLikes(user_id: string, recipeLikes: Array<{ recipe_id: string, likeStatus: boolean | null }>) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, recipeLikes: recipeLikes })
    };
    const response = await fetch(backendUrl + 'likeRecipe', requestOptions);
    var data = response.json();
    return data;
}

async function deleteUsersRecipe(user_id: string, recipe_id: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, recipe_id: recipe_id })
    };
    const response = await fetch(backendUrl + 'deleteUsersRecipe', requestOptions);
    var data = response.json();
    return data;
}

// async function likeRecipe(user_id:string, recipe_id:string, like:boolean){
//     const requestOptions = {
//         method: 'POST',
//         headers: { "content-type": "application/json" },
//         body: JSON.stringify({ user_id: user_id, recipe_id:recipe_id, like:like })
//     };
//     const response = await fetch(backendUrl + 'likeRecipe', requestOptions);
//     var data = response.json();
//     return data;
// }

// async function dislikeRecipe(user_id:string, recipe_id:string){
//     const requestOptions = {    
//         method: 'POST',
//         headers: { "content-type": "application/json" },
//         body: JSON.stringify({ user_id: user_id, recipe_id:recipe_id })
//     };
//     const response = await fetch(backendUrl + 'dislikeRecipe', requestOptions);
//     var data = response.json();
//     return data;
// }



async function loadRecipes(doe_id: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ doe_id: doe_id })
    };
    const response = await fetch(backendUrl + 'retrieveDoeRecipes', requestOptions);
    var data = await response.json();
    // data = generateNewMealsKeys(data);
    //TODO add locked:false to all ingredients ? 
    return data;
}


async function loadUserDayOfEating(user_id: string, day: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, day: day })
    };
    const response = await fetch(backendUrl + 'loadUserDayOfEating', requestOptions);
    var data = await response.json();
    return data.recipes;
}

// async function addToUserDayOfEating(user_id:string, day:Date, meals:dashboardState){
//     const requestOptions = {
//         method: 'POST',
//         headers: { "content-type": "application/json" },
//         body: JSON.stringify({ user_id: user_id, day: day.getTime(), data:meals, to_add:true})
//     };  
//     const response = await fetch(backendUrl+'addToUserDayOfEating', requestOptions);
//     var data = await response.json();
//     return data;
// }


async function saveEmailAdress(email: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ email: email })
    };
    const response = await fetch(backendUrl + 'saveEmail', requestOptions);
    var data = await response.json();
}


async function saveRecipeToDatabase(ingredients: Ingredient[], recipeName: string, recipeSource: string, recipeSourceURL: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ ingredientList: ingredients, recipeName: recipeName, source: recipeSource, sourceURL: recipeSourceURL })
    };
    const response = await fetch(backendUrl + 'saveRecipe', requestOptions);
    var data = await response.json();
}


async function saveDoeToDatabase(meals: mealList) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ meals: meals })
    };
    const response = await fetch(backendUrl + 'saveDoe', requestOptions);
    var data = await response.json();
}

async function getUserGoal(user_id: string, dispatch: any) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id })
    };
    const response = await fetch(backendUrl + 'retrieveUserGoals', requestOptions);
    var data = await response.json();
    if('errors' in data && data.errors.length > 0){
        data.errors.map((error: { id: string; errorMessage: string }) => {
            dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
        });
    }
    debugger;
    return data.data;
}


async function saveUserGoal(user_id: string, goal: OnboardingUserFormData) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, user_goal: goal })
    };
    const response = await fetch(backendUrl + 'writeUserGoal', requestOptions);
    var data = await response.json();
}


async function searchIngredientsUSDAAutocomplete(ingredientTextSearch: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ ingredient_text_search: ingredientTextSearch })
    };
    const response = await fetch(backendUrl + 'searchIngredientUSDA', requestOptions);
    var data = await response.json();
    return data;
}


async function searchRecipes(recipeTextSearch: string) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ recipe_text_search: recipeTextSearch })
    };
    const response = await fetch(backendUrl + 'searchRecipeAutocomplete', requestOptions);
    var data = await response.json();
    return data;
}


async function writeUserDayOfEating(user_id: string, day: string, meals: mealList, sumMacros: sumMacros, to_add: boolean = false) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id, day: day, data: meals, macros: sumMacros, to_add: to_add })
    };
    const response = await fetch(backendUrl + 'writeUserDayOfEating', requestOptions);
    var data = await response.json();
    return data;
}

async function loadUserGoal(user_id: string, dispatch: any) {
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ user_id: user_id })
    };
    const response = await fetch(backendUrl + 'retrieveUserGoals', requestOptions);
    var data = await response.json();
    if ('errors' in data && data.errors.length > 0) {
        data.errors.map((error: { id: string; errorMessage: string }) => {
            dispatch(addError({ id: error.id, errorMessage: error.errorMessage }));
        });
    }
    debugger;
    return data.data;
}

export {
    saveRecipeToDatabase,
    getUSDAIngredientMacros,
    getUSDAIngredientUnits,
    getRecipeInfo,
    updateRecipeLikes,
    // likeRecipe,
    deleteUsersRecipe,
    loadUserDayOfEating,
    loadUserGoal,
    loadRecipes,
    saveEmailAdress,
    searchIngredientsUSDAAutocomplete,
    searchRecipes,
    writeUserDayOfEating,
    saveUserGoal,
    getUserGoal
};