import { useEffect } from "react";
import { Recipe } from "../../../../utils/types";

type recipeCardProps = {
    recipe: Recipe;
};

function RecipeCard(props: recipeCardProps) {

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%', // 16:9 aspect ratio
            borderRadius: '15px', // Add rounded corners
            overflow: 'hidden', // Ensure content respects the border radius
            height: '100%',
            // width: '100%'
        }}>
            <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundImage: `url(${props.recipe.imageURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(20px)', // Apply blur effect
                zIndex: '0', // Ensure it is behind the main image
               
            }}></div>
            <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                zIndex: '1', // Ensure it is above the blurred image
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img
                    src={props.recipe.imageURL}
                    alt={props.recipe.recipeName}
                    style={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '15px' // Match the border radius of the parent
                    }}
                />
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                height: '50%', // Adjust the height as needed
                background: 'linear-gradient(to top, black, transparent)',
                borderRadius: '0 0 15px 15px', // Match the border radius of the parent
                zIndex: '2' // Ensure it is above the main image
            }}></div>
            <div style={{
                position: 'absolute',
                bottom: '10px', // Adjust the position as needed
                width: '100%',
                color: 'white',
                // textAlign: 'center',
                padding: '10px',
                boxSizing: 'border-box',
                zIndex: '3', // Ensure it is above all other elements
                textAlign: 'left', // Align text to the left

            }}>
                {/* Recipe # {props.recipe.recipeId}<br /> */}
                <h3 style={{textAlign:'center'}}>{props.recipe.recipeName}</h3><br />
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {props.recipe.ingredients.map((ingredient, index) => (
                        <li key={index}>{ingredient.name}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export { RecipeCard };