import backendUrl from "../../../utils/api_connectors/backend";



async function loadOnboardingRecipes(){
    const requestOptions = {
        method: 'POST',
        headers: { "content-type": "application/json" },
    };
    const response = await fetch(backendUrl + 'retrieveOnboardingRecipes', requestOptions);
    var data = await response.json();
    return data;
}


export {loadOnboardingRecipes}