import { IonList, IonSpinner, IonItem, IonLabel, IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../redux/store";
import { Recipe, emptyRecipe } from "../../../utils/types";
import { getRecipesToClean, getUsersMyRecipes } from "../services/RecipePageServices";
import { RecipeCardPresentation } from "./RecipeCardPresentation";
import { add } from "ionicons/icons";
import '../../MealPlan/Components/FabButton/FabButton.css';
import { setViewingRecipeId } from "../../../redux/recipeSlice";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";


type CleanRecipesProps = {

}

function CleanRecipes(props: CleanRecipesProps) {

    const user = useSelector((state: RootState) => state.auth.userUid);
    const [recipeStack, setRecipeStack] = useState<Recipe[]>([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();

    async function initializeStack() {
        var temp: Recipe[] = [];
        // if (props.segment == "browse") {
        temp = await getRecipesToClean();
        // }
        // else if (props.segment == "liked") {
        //     temp = await getUsersRecipes(user!);
        // }
        setRecipeStack(temp);
        setLoading(false);
    }

    useEffect(() => {
        initializeStack();
    }, [])

    function onClickAddMyRecipe() {
        var tempRecipe = emptyRecipe;
        tempRecipe = {...tempRecipe}
        dispatch(setViewingRecipeId(tempRecipe.recipeId));
        history.push({pathname: '/recipes/singleRecipeView/', state: {recipe: tempRecipe, usersRecipe: true, editMode:true}})
    }

    return (
        <>
            {loading ?
                <LoadingGenie/>
                :
                recipeStack.map((recipe, index) =>
                    <RecipeCardPresentation key={index} recipe={recipe} disableLike={true} usersRecipe={true} />
                )}

            <IonFab slot="fixed" vertical="bottom" horizontal="end" className="my-fab">
            <IonFabButton className="main-button" onClick={onClickAddMyRecipe}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
        </>
    )
}

export { CleanRecipes }