import { IonBackButton, IonButton, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonTitle, IonToolbar } from "@ionic/react"
import { logoGoogle, mail, logoApple } from "ionicons/icons"
import { signInWithEmailAndPassword } from "firebase/auth"
import { UserContext } from "../../../../App";
import { useContext, useEffect } from "react";
import { signInWithGoogle } from "../../../../utils/firebase";
import './Slide5CreateAccount.css'; // Import your CSS file
import SignupPageComponent from "./SignUpPage";

type slide5CreateAccountProps = {
  setUserIsOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
  goToPrevSlide: () => void;
}

function Slide5CreateAccount(props: slide5CreateAccountProps) {
  // const { user, isPremium, isAdmin } = useContext(UserContext);
  // useEffect(() => { console.log('user ' + user?.uid); }, [user]);


    return(
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Create Account</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
        {/* <div className="container"> */}
          <SignupPageComponent isUserOnboarding={true} setIsUserOnboarding={props.setUserIsOnboarding}/>
          <div className='button-container'>
          <IonButton shape="round" onClick={props.goToPrevSlide}>Prev</IonButton>
          </div>
          </IonContent>
          </IonPage>
          
         
    )
}

export {Slide5CreateAccount}