import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from "@ionic/react"

type CaloricTargetCardProps = {
    caloricTarget: number 

}

function CaloricTargetCard(props:CaloricTargetCardProps) {
  
  return(
    <IonCard >
            <IonCardHeader>
                <IonCardTitle>Caloric target</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                {props.caloricTarget}
            </IonCardContent>
        </IonCard>
  )
}

export {CaloricTargetCard}