import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from "@ionic/react";
import { BarController, BarElement, CategoryScale, Chart, Legend, LineController, LineElement, LinearScale, PointElement } from "chart.js";
import { useRef, useEffect, useState, useContext } from "react";
import { getDailyMacrosData, getLast7Days, getLast7DaysFullString } from "../../Services/dashboardServices";
import { UserContext } from "../../../../App";
import { dateToString, getDateRelative, getTodaysDay } from "../../../../utils/misc-utils";
import { loadUserGoal } from "../../../../utils/db_connectors/database-connectors";
import { SpecificMacroData } from "../dashboardPage";
Chart.register(BarController, BarElement, LineController, CategoryScale, LinearScale, PointElement, LineElement, Legend);

type DailyCaloricInputCardProps = {
    dailyCaloricData: SpecificMacroData[];
    caloricTarget: number;
    sevenDayCalorieInputAverage: number;
}

function DailyCaloricInputCard(props: DailyCaloricInputCardProps) {
    
    const chartContainer = useRef(null);

    // Replace this with your data
    const dataPoints2 = new Array(7).fill(props.caloricTarget);
    const dataPoints3 = new Array(7).fill(props.sevenDayCalorieInputAverage);

    const last7daysFullStrings = getLast7DaysFullString();
    const last7daysDisplay = getLast7Days();

    const dailyCaloricDataArray = last7daysFullStrings.map(date => {
        const dataForDate = props.dailyCaloricData.find(data => data.date === date);
        return dataForDate ? dataForDate.value : null;
    });

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, {
                type: 'bar', // Set the default type to 'bar'
                data: {
                    labels: getLast7Days(),
                    datasets: [{
                        type: 'bar', // This will make 'Caloric input' a bar chart
                        label: 'Caloric input',
                        borderColor: 'rgb(75, 192, 192)',
                        data: dailyCaloricDataArray,
                    }, {
                        type: 'line', // This will keep 'Caloric target' as a line chart
                        label: 'Caloric target',
                        borderColor: 'rgb(255, 99, 132)',
                        data: dataPoints2,
                    },
                    {
                        type: 'line', // This will keep 'Caloric target' as a line chart
                        label: 'Caloric daily average',
                        borderColor: 'rgb(100, 149, 237)',
                        data: dataPoints3,
                    }]
                },
                options: {
                    // events: [], // Disable all interactions
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                        }
                    }
                }
            });

            return () => {
                newChartInstance.destroy();
            };
        }
    }, [chartContainer, dailyCaloricDataArray, dataPoints2]);

    return (
        <IonCard >
            <IonCardHeader>
                <IonCardTitle>Daily caloric input</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <canvas ref={chartContainer} />
            </IonCardContent>
        </IonCard>
    )
}

export { DailyCaloricInputCard }