import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { CategoryScale, Chart, LineController, LineElement, LinearScale, PointElement } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { DailyCaloricInputCard } from "./DailyCaloricInputCard/DailyCaloricInputCard";
import { UserContext } from "../../../App";
import { getTodaysDay, dateToString, getDateRelative } from "../../../utils/misc-utils";
import { getDailyMacrosData, getMeanCaloricInput, getWeightData } from "../Services/dashboardServices";
import { loadUserGoal } from "../../../utils/db_connectors/database-connectors";
import { CaloricTargetCard } from "./CaloricTargetCard/CaloricTargetCard";
import { SevenDayCaloricInputAverageCard } from "./SevenDayCaloricInput/SevenDayCaloricInputAverageCard";
import './dashboardPage.css';
import { WeightTrackingCardComponent } from "./WeightTrackingCard/weightTrackingCard";
import { weightData } from "../../../utils/types";
import { Loading } from "../../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LoadingGenie } from "../../../components/LoadingGenie/LoadingGenie";
import { WeightEntry, addWeightEntry } from "../../../redux/dashboardSlice";

export type SpecificMacroData = {
    date: string,
    value: number | null
};

type MacrosData = {
    date: string,
    macros: {
        calories: number,
        protein: number,
        carbs: number,
        fat: number
    } | null
}


type dashboardPageComponentProps = {
    key: number
}

function DashboardPageComponent(props: dashboardPageComponentProps) {
    const [dailyMacrosData, setDailyMacrosData] = useState<MacrosData[]>([]);
    const [dailyCaloricData, setDailyCaloricData] = useState<SpecificMacroData[]>([]); // [calories, protein, carbs, fat
    const [userGoals, setUserGoals] = useState<any>({});
    // const [weightData, setWeightData] = useState<weightData[]>(); // [date, weight
    const weightData: Record<string,number> = useSelector((state: RootState) => state.dashboard.weightEntries);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    // const { user, isPremium, isAdmin } = useContext(UserContext);
    const user = useSelector((state: RootState) => state.auth.userUid);

    var todaysDate = getTodaysDay();
    var sevendaysago = dateToString(getDateRelative(-7));
    var sevenDayCaloricInput: number = getMeanCaloricInput(dailyCaloricData);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoading) {
            getWeightDataAsync().then((data) => {
                data.forEach((entry: WeightEntry) => {
                    dispatch(addWeightEntry(entry));
                })
            });


            Promise.all([
                getMacrosData(),
                loadUserGoal(user!, dispatch).then((data) => {
                    setUserGoals((prev: any) => data);
                }),
            ]).finally(() => {
                setIsLoading(prev => false);
            });
        }
    }, []);


    async function getMacrosData() {
        var temp = await getDailyMacrosData(user!, sevendaysago, todaysDate);
        setDailyMacrosData(prev => temp);
        const caloriesData = getSpecificMacroData(temp, 'calories');
        setDailyCaloricData(prev => caloriesData);
    }


    function getSpecificMacroData(macrosData: MacrosData[], macro: string) {
        return macrosData.map(({ date, macros }) => ({
            date,
            value: macros ? macros[macro as keyof MacrosData['macros']] : null
        }));
    }

    async function getWeightDataAsync() {
        return getWeightData(user!, sevendaysago, todaysDate);
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Dashboard</IonTitle>
                </IonToolbar>
            </IonHeader>
            {isLoading ? <IonContent><LoadingGenie /></IonContent> :
                <IonContent>
                    <IonRow>
                        <IonCol>
                            <CaloricTargetCard caloricTarget={userGoals?.caloricTarget} />
                        </IonCol>
                        <IonCol>
                            <SevenDayCaloricInputAverageCard
                                sevenDayAverage={sevenDayCaloricInput}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <DailyCaloricInputCard
                                dailyCaloricData={dailyCaloricData}
                                caloricTarget={userGoals?.caloricTarget}
                                sevenDayCalorieInputAverage={sevenDayCaloricInput}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {weightData ?
                                <WeightTrackingCardComponent weightData={weightData} /> :
                                <></>
                            }
                        </IonCol>
                    </IonRow>
                </IonContent>
            }
        </IonPage>
    );
}

export { DashboardPageComponent };